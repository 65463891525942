export default {
  state: {
    windowWidth: null,
    windowHeight: null,
    colorOne: '#ffe100',
    systemName: '',
    logo: '',
    pageTitle: ''
  },
  mutations: {
    window_resize(state, size) {
      state.windowWidth = size.w;
      state.windowHeight = size.h;
    },
    color_one(state, colorOne) {
      state.colorOne = colorOne;
    },
    system_name(state, name) {
      state.systemName = name;
    },
    logo(state, logo) {
      state.logo = logo;
    },
    pageTitle(state, title) {
      state.pageTitle = title;
    }
  },
  actions: {
    window_resize(context, size) {
      context.commit('window_resize', size);
    },
    color_one(context, colorOne) {
      context.commit('color_one', colorOne);
    },
    system_name(context, name) {
      context.commit('system_name', name);
    },
    logo(context, logo) {
      context.commit('logo', logo);
    },
    pageTitle(context, title) {
      context.commit('pageTitle', title);
    }
  }
};
