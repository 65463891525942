<template>
  <div>
    <div class="copy_right">{{ copyRight }}</div>
    <div class="copy_right">{{ devCompany }}提供技术支持</div>
  </div>
</template>

<script>
import { devCompany, copyRight } from '../config/page-foot';
export default {
  data() {
    return {
      devCompany: devCompany,
      copyRight: copyRight
    };
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.copy_right {
  line-height: 30px;
  text-align: center;
  color: #dcdcdc;
}
</style>
