var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "chat_input_com" }, [
      _c(
        "div",
        { staticClass: "input_box", attrs: { contenteditable: "true" } },
        [
          _c("img", {
            staticClass: "emoji",
            attrs: {
              src: require("../../assets/image/emoji/emoji_01.png"),
              alt: "",
            },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }