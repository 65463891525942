import Vue from 'vue';
import msgboxVue from './Main.vue';

const MessageBoxConstructorL = Vue.extend(msgboxVue);

let currentMsg, instance;
let msgQueue = [];
const defaultCallbackL = (action) => {
  if (currentMsg) {
    if (action == 'confirm') {
      currentMsg.resolve(action);
    } else {
      currentMsg.reject(action);
    }
  }
};
const initInstanceL = () => {
  instance = new MessageBoxConstructorL({
    el: document.createElement('div')
  });
  instance.callback = defaultCallbackL;
};
const showNextMsgL = () => {
  if (!instance) {
    initInstanceL();
  }
  instance.action = '';
  if (!instance.visible && msgQueue.length > 0) {
    currentMsg = msgQueue.shift();
    let options = currentMsg.options;
    for (let prop in options) {
      instance[prop] = options[prop];
    }
    document.body.appendChild(instance.$el);

    Vue.nextTick(() => {
      instance.visible = true;
    });
  }
};
const MessageBoxL = function (message, title) {
  return new Promise((resolve, reject) => {
    msgQueue.push({
      options: {
        message: message,
        title: title
      },
      resolve: resolve,
      reject: reject
    });
    showNextMsgL();
  });
};
MessageBoxL.close = () => {
  console.log('close');
  instance.doClose();
  instance.visible = false;
  msgQueue = [];
  currentMsg = null;
};

export default MessageBoxL;
