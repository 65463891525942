<template>
  <div class="text_content" :style="{ backgroundColor: mainColor, color: fontColor }">
    <p ref="content" v-html="content"></p>
  </div>
</template>
<script>
export default {
  mixins: [],
  props: {
    mainColor: {
      type: String,
      required: true
    },
    fontColor: {
      type: String,
      default: '#333'
    },
    content: {
      type: String
    }
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {}
};
</script>
<style lang="scss">
.text_content {
  max-width: 60%;
  padding: 12px;
  border-radius: 6px;
  overflow-x: hidden;
  p {
    word-break: break-all;
    line-height: 24px;
  }
  .emoji {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    display: inline-block;
  }
}
</style>
