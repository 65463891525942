import lazyRoute from '@/router/lazyRoute';
import { customMenuMap } from '../../js/menu';
const YpOrder = () => {
  lazyRoute.show();
  return import('@/vuePage/order/YpOrder.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const OrderDetail = () => {
  lazyRoute.show();
  return import('@/vuePage/order/OrderDetail.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const PhotoOrder = () => {
  lazyRoute.show();
  return import('@/vuePage/order/PhotoOrder.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const PhotoorderDetail = () => {
  lazyRoute.show();
  return import('@/vuePage/order/PhotoorderDetail.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const OfflineYpOrder = () => {
  lazyRoute.show();
  return import('@/vuePage/offline/OfflineYpOrder.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const OfflineGeneralOrder = () => {
  lazyRoute.show();
  return import('@/vuePage/offline/OfflineGeneralOrder.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const OfflineOrderDetail = () => {
  lazyRoute.show();
  return import('@/vuePage/offline/OfflineOrderDetail.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const OfflineOrderDetail2 = () => {
  lazyRoute.show();
  return import('@/vuePage/offline/OfflineOrderDetail2.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};

export default [
  {
    path: 'offlineOrderDetail2',
    name: 'offlineOrderDetail2',
    meta: {
      menuId: customMenuMap.offlineGeneralOrder
    },
    component: OfflineOrderDetail2
  },
  {
    path: 'offlineOrderDetail',
    name: 'offlineOrderDetail',
    meta: {
      menuId: customMenuMap.offlineYpOrder
    },
    component: OfflineOrderDetail
  },
  {
    path: 'offlineGeneralOrder',
    name: 'offlineGeneralOrder',
    meta: {
      menuId: customMenuMap.offlineGeneralOrder
    },
    component: OfflineGeneralOrder
  },
  {
    path: 'offlineYpOrder',
    name: 'offlineYpOrder',
    meta: {
      menuId: customMenuMap.offlineYpOrder
    },
    component: OfflineYpOrder
  },
  {
    path: 'yporder',
    name: 'yporder',
    meta: {
      menuId: customMenuMap.ypOrder
    },
    component: YpOrder
  },
  {
    path: 'orderdetail',
    name: 'orderdetail',
    meta: {
      menuId: customMenuMap.ypOrder
    },
    component: OrderDetail
  },
  {
    path: 'photoOrder',
    name: 'photoOrder',
    meta: {
      menuId: customMenuMap.photoOrder
    },
    component: PhotoOrder
  },
  {
    path: 'photoorderDetail',
    name: 'photoorderDetail',
    meta: {
      menuId: customMenuMap.photoOrder
    },
    component: PhotoorderDetail
  }
];
