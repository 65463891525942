import lazyRoute from '@/router/lazyRoute';
import { customMenuMap } from '../js/menu';
const Statistics = () => {
  lazyRoute.show();
  return import('@/vuePage/setting/Statistics.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const AiXiuTu = () => {
  lazyRoute.show();
  return import('@/vuePage/xiutu/XiuTu.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const DownImages = () => {
  lazyRoute.show();
  return import('@/vuePage/xiutu/DownImages.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const Repair = () => {
  lazyRoute.show();
  return import('@/vuePage/xiutu/Repair.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const CreativeTemplateManage = () => {
  lazyRoute.show();
  return import('@/vuePage/creativeAi/templateManage.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const SingleAi = () => {
  lazyRoute.show();
  return import('@/vuePage/creativeAi/singleAi.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const MultiAi = () => {
  lazyRoute.show();
  return import('@/vuePage/creativeAi/multiAi.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const CreativeTaskPreview = () => {
  lazyRoute.show();
  return import('@/vuePage/creativeAi/orderPreview.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
import App from '@/vuePage/App.vue';
import orderRoutes from './modules/order';
import productsRoutes from './modules/products';
import customDecorationRoutes from './modules/customDecoration';
import usersRoutes from './modules/users';
import activityRoutes from './modules/activity';
import settingRoutes from './modules/setting';
import withdrawRoutes from './modules/withdraw';
import yxtRoutes from './modules/yxt';
import serviceRoutes from './modules/service';
import distributionRoutes from './modules/distribution';
import xiutu from './modules/xiutu';
import ai from './modules/ai';
import creativeAi from './modules/creativeAi';
export default [
  {
    path: '',
    component: App,
    children: [
      {
        path: '',
        redirect: 'statistics'
      },
      {
        path: 'statistics',
        name: 'statistics',
        meta: {
          menuId: customMenuMap.index
        },
        component: Statistics
      },
      ...orderRoutes,
      ...productsRoutes,
      ...customDecorationRoutes,
      ...usersRoutes,
      ...activityRoutes,
      ...settingRoutes,
      ...withdrawRoutes,
      ...yxtRoutes,
      ...serviceRoutes,
      ...distributionRoutes,
      ...xiutu,
      ...ai,
      ...creativeAi,
    ]
  },
  {
    path: '/downImages',
    component: DownImages
  },
  {
    path: '/aiXiuTu',
    component: AiXiuTu
  },
  {
    path: '/repair',
    component: Repair
  },
  {
    path: '/CreativeTemplateManage',
    component: CreativeTemplateManage
  },
  {
    path: '/SingleAi',
    component: SingleAi
  },
  {
    path: '/MultiAi',
    component: MultiAi
  },
  {
    path: '/CreativeTaskPreview',
    component: CreativeTaskPreview
  },
];
