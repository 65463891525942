import lazyRoute from '@/router/lazyRoute';
import { customMenuMap } from '../../js/menu';
const XiuTuList = () => {
  lazyRoute.show();
  return import('@/vuePage/xiutu/XiuTuList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const XiuTuRecharge = () => {
  lazyRoute.show();
  return import('@/vuePage/xiutu/XiuTuRecharge.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const SpreadList = () => {
  lazyRoute.show();
  return import('@/vuePage/xiutu/SpreadList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const SpreadStatistic = () => {
  lazyRoute.show();
  return import('@/vuePage/xiutu/SpreadStatistic.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};

export default [
  {
    path: 'xiuTuList',
    name: 'xiuTuList',
    meta: {
      menuId: customMenuMap.xiuTuHistory
    },
    component: XiuTuList
  },
  {
    path: 'xiutuSpreadList',
    name: 'SpreadList',
    meta: {
      menuId: customMenuMap.xiuTuSpreadList
    },
    component: SpreadList
  },
  {
    path: 'xiutuSpreadStatistic',
    name: 'SpreadStatistic',
    meta: {
      menuId: customMenuMap.xiuTuSpreadStatistic
    },
    component: SpreadStatistic
  },
  {
    path: 'xiuTuRecharge',
    name: 'XiuTuRecharge',
    meta: {
      menuId: customMenuMap.xiuTuRecharge
    },
    component: XiuTuRecharge
  }
];
