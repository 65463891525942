<template>
  <div class="chat_input_com">
    <div class="input_box" contenteditable="true">
      <img class="emoji" src="../../assets/image/emoji/emoji_01.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  mixins: [],
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.chat_input_com {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .input_box {
    padding: 8px 10px;
    cursor: text;
    outline: none;
    line-height: 16px;
    .emoji {
      width: 24px;
      height: 24px;
      vertical-align: middle;
      display: inline-block;
    }
  }
}
</style>
