import lazyRoute from '@/router/lazyRoute';
import { customMenuMap } from '../../js/menu';
const CreativeClassify = () => {
  lazyRoute.show();
  return import('@/vuePage/creativeAi/classify.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const CreativeUserList = () => {
  lazyRoute.show();
  return import('@/vuePage/creativeAi/userList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const CreativeTaskList = () => {
  lazyRoute.show();
  return import('@/vuePage/creativeAi/TaskList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const CreativeRechargeList = () => {
  lazyRoute.show();
  return import('@/vuePage/creativeAi/RechargeList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const CreativeSpreadList = () => {
  lazyRoute.show();
  return import('@/vuePage/creativeAi/SpreadList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const CreativeSpreadStatistic= () => {
  lazyRoute.show();
  return import('@/vuePage/creativeAi/SpreadStatistic.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const CreativeClassType= () => {
  lazyRoute.show();
  return import('@/vuePage/creativeAi/classType.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
export default [
  {
    path: 'CreativeClassify',
    name: 'CreativeClassify',
    meta: {
      menuId: customMenuMap.CreativeClassify
    },
    component: CreativeClassify
  },
  {
    path: 'CreativeUserList',
    name: 'CreativeUserList',
    meta: {
      menuId: customMenuMap.CreativeUserList
    },
    component: CreativeUserList
  },
  {
    path: 'CreativeTaskList',
    name: 'CreativeTaskList',
    meta: {
      menuId: customMenuMap.CreativeTaskList
    },
    component: CreativeTaskList
  },
  {
    path: 'CreativeRechargeList',
    name: 'CreativeRechargeList',
    meta: {
      menuId: customMenuMap.CreativeRechargeList
    },
    component: CreativeRechargeList
  },
  {
    path: 'CreativeSpreadList',
    name: 'CreativeSpreadList',
    meta: {
      menuId: customMenuMap.CreativeSpreadList
    },
    component: CreativeSpreadList
  },
  {
    path: 'CreativeSpreadStatistic',
    name: 'CreativeSpreadStatistic',
    meta: {
      menuId: customMenuMap.CreativeSpreadStatistic
    },
    component: CreativeSpreadStatistic
  },
  {
    path: 'CreativeClassType',
    name: 'CreativeClassType',
    meta: {
      menuId: customMenuMap.CreativeClassType
    },
    component: CreativeClassType
  },
];
