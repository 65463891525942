import config from '@/config/common';
import { file_type_map } from '../../js/file';
import { vip_level_name } from '../../js/user';
import ossUpload from '../../js/upload';
export default {
  data() {
    return {
      emojiList: [],
      show_service: false,
      loadingMsg: false,
      socket: null,
      serviceId: null,
      serviceAvatar: '',
      customerList: [],
      msgTemplate: [],
      userId: null,
      showChatFrame: false
    };
  },
  created() {
    this.init_service();
  },
  computed: {
    userInfo() {
      let info = {};
      if (this.userId) {
        this.customerList.findIndex((item) => {
          if (item.userId == this.userId && item.userInfo) {
            info = item.userInfo;
            return true;
          }
        });
      }
      return info;
    },
    msgList() {
      let msg = [];
      if (this.userId) {
        this.customerList.findIndex((item) => {
          if (item.userId == this.userId && item.msgList.length > 0) {
            msg = item.msgList;
            return true;
          }
        });
      }
      return msg;
    },
    noMoreMsg() {
      let noMore = false;
      if (this.userId) {
        this.customerList.findIndex((item) => {
          if (item.userId == this.userId && item.msgList.length > 0) {
            noMore = item.noMoreMsg;
            return true;
          }
        });
      }
      return noMore;
    },
    newMsg() {
      let newMsg = false;
      this.customerList.findIndex((item) => {
        if (item.notRead) {
          newMsg = true;
          return true;
        }
      });
      return newMsg;
    }
  },
  methods: {
    customer_sort() {
      let newArr = [];
      let oldArr = [];
      for (let customer of this.customerList) {
        if (customer.notRead) {
          newArr.push(customer);
        } else {
          oldArr.push(customer);
        }
      }
      this.customerList = [...newArr, ...oldArr];
    },
    show_chat_frame() {
      this.showChatFrame = true;
    },
    close_chat() {
      if (this.userId && this.socket.readyState == WebSocket.OPEN) {
        this.socket.send(
          JSON.stringify({
            userId: this.userId,
            userType: this.msgFromMap.user,
            msgType: this.msgTypeMap.close
          })
        );
        this.userId = null;
        this.customerList.findIndex((item) => {
          if (item.active) {
            item.active = false;
            return true;
          }
        });
      } else if (this.userId && this.socket.readyState != WebSocket.OPEN) {
        this.init_socket(this.serviceId);
      }
    },
    send_img(resUrl) {
      if (this.userId && this.socket.readyState == WebSocket.OPEN) {
        let data = {
          customerServiceId: this.serviceId,
          userId: this.userId,
          msgType: this.msgTypeMap.img,
          userType: this.msgFromMap.service,
          resUrl: resUrl
        };
        this.socket.send(JSON.stringify(data));
        this.get_new_message(
          JSON.stringify({
            customerServiceId: this.serviceId,
            userId: this.userId,
            msgType: this.msgTypeMap.img,
            userType: this.msgFromMap.service,
            resUrl: resUrl,
            customerServiceHeadImgUrl: this.serviceAvatar
          })
        );
      } else if (this.userId && this.socket.readyState != WebSocket.OPEN) {
        this.init_socket(this.serviceId);
      }
    },
    send_msg({ content = '', resUrl = '' } = {}) {
      if (this.userId && this.socket.readyState == WebSocket.OPEN) {
        let data = {
          customerServiceId: this.serviceId,
          userId: this.userId,
          msgType: this.msgTypeMap.text,
          userType: this.msgFromMap.service,
          content: content
        };
        this.socket.send(JSON.stringify(data));
        this.get_new_message(
          JSON.stringify({
            customerServiceId: this.serviceId,
            userId: this.userId,
            msgType: this.msgTypeMap.text,
            userType: this.msgFromMap.service,
            content: content,
            customerServiceHeadImgUrl: this.serviceAvatar
          })
        );
      } else if (this.userId && this.socket.readyState != WebSocket.OPEN) {
        this.init_socket(this.serviceId);
      }
    },
    init_socket(serviceId) {
      let socket = new WebSocket(`${config.socketUrl}?userId=${serviceId}&userType=2`);
      socket.onopen = () => {
        console.log('链接成功');
      };
      socket.onmessage = (e) => {
        this.get_new_message(e.data);
      };
      socket.onclose = (e) => {
        console.log('断开链接');
      };
      let keepConnect = setInterval(() => {
        if (socket.readyState == WebSocket.OPEN) {
          socket.send('心跳内容');
        } else {
          clearInterval(keepConnect);
          this.init_socket(this.serviceId);
        }
      }, 5000);
      this.socket = socket;
    },
    async init_service() {
      try {
        let serviceInfo = await this.request_service_info();
        if (serviceInfo != null) {
          this.show_service = true;
          let serviceId = serviceInfo.customerServiceId;
          this.init_socket(serviceId);
          this.serviceId = serviceId;
          this.serviceAvatar = serviceInfo.headImgUrl;
          this.init_emoji_list();
          this.init_customer_list();
          this.init_msg_template(serviceId);
        }
      } catch (err) {
        console.log(err);
      }
    },
    init_emoji_list() {
      this.request_emoji_list()
        .then((res) => {
          let arr = [];
          for (let emoji of res) {
            arr.push({
              title: emoji.code,
              src: this.imgBase + emoji.resUrl
            });
          }
          this.emojiList = arr;
        })
        .catch((err) => {
          this.showWarn(err.message);
        });
    },
    get_new_message(dataStr) {
      let data = JSON.parse(dataStr);
      let userIndex = this.customerList.findIndex((item) => {
        if (item.userId === data.userId) {
          if (this.userId == data.userId) {
            item.msgList.push(this.initMsg(data));
            this.$nextTick(() => {
              this.$refs.chatFrame.scrollToBottom();
            });
            if (data.userType == this.msgFromMap.user) {
              this.request_update_log_state([data.imChatLogId]);
            }
          } else {
            item.notRead = true;
            this.customer_sort();
          }
          item.content = this.simply_msg(data);
          return true;
        }
      });
      if (userIndex == -1) {
        data.userNickname = data.userNickName;
        let customer = this.init_customer(data);
        this.customerList.unshift(customer);
      }
    },
    init_msg_template(serviceId) {
      this.request_msg_template(serviceId)
        .then((res) => {
          let arr = [];
          for (let template of res) {
            arr.push({
              id: template.quickReplyId,
              simpleWord: template.simpleWord,
              content: template.content
            });
          }
          this.msgTemplate = arr;
        })
        .catch((err) => {
          this.showWarn(err.message);
        });
    },
    moreMessage() {
      this.update_user_msg(this.userId);
    },
    init_customer(customer) {
      return {
        name: customer.userNickname,
        userId: customer.userId,
        content: this.simply_msg(customer),
        active: false,
        userInfo: null,
        msgList: [],
        noMoreMsg: false,
        notRead: customer.unreadMessages > 0 ? true : false
      };
    },
    init_customer_list() {
      this.request_customer_list()
        .then((res) => {
          let arr = [];
          let customerList = this.customerList;
          for (let customer of res) {
            let currentIndex = customerList.findIndex((item) => {
              if (item.userId == customer.userId) {
                return true;
              }
            });
            if (currentIndex == -1) {
              arr.push(this.init_customer(customer));
            }
          }
          this.customerList = [...arr, ...customerList];
          this.customer_sort();
        })
        .catch((err) => {
          this.showWarn(err.message);
        });
    },
    simply_msg(msg) {
      let content = '';
      let msgTypeMap = this.msgTypeMap;
      switch (Number(msg.msgType)) {
        case msgTypeMap.text:
          content = msg.content;
          break;
        case msgTypeMap.img:
          content = '[图片]';
          break;
        case msgTypeMap.system:
          content = '系统消息';
          break;
      }
      return content;
    },
    toggle_chat_person(userId) {
      let customerList = this.customerList.slice(0);
      for (let customer of customerList) {
        if (userId == customer.userId) {
          customer.active = true;
          customer.msgList = [];
          customer.noMoreMsg = false;
          customer.notRead = false;
          if (customer.userInfo === null) {
            this.init_user_info(userId);
          }
          if (customer.msgList.length === 0) {
            this.update_user_msg(userId);
          }
        } else {
          customer.active = false;
        }
      }
      this.userId = userId;
      this.customerList = customerList;
    },
    init_user_info(userId) {
      this.request_user_info(userId)
        .then((res) => {
          let info = {
            nickname: res.nickname,
            account: res.totalAccount,
            vipLevelName: vip_level_name(res.vipLevel),
            consume: res.totalConsume,
            phone: res.phone,
            userId: userId
          };
          this.customerList.findIndex((item) => {
            if (item.userId == userId) {
              item.userInfo = info;
            }
          });
        })
        .catch((err) => {
          this.showWarn(err.message);
        });
    },
    update_user_msg(userId) {
      if (this.loadingMsg) {
        return;
      }
      this.loadingMsg = true;
      let customerIndex = this.customerList.findIndex((item) => {
        if (userId == item.userId) {
          return true;
        }
      });
      if (customerIndex > -1) {
        let customer = this.customerList[customerIndex];
        let lastChatLogId = null;
        if (customer.msgList.length > 0) {
          lastChatLogId = customer.msgList[0].chatLogId;
        }
        this.request_chat_log({
          serviceId: this.serviceId,
          userId: customer.userId,
          lastChatLogId: lastChatLogId
        })
          .then((res) => {
            this.loadingMsg = false;
            if (res.length > 0) {
              let msgList = [];
              let logIds = [];
              for (let msg of res) {
                msgList.unshift(this.initMsg(msg));
                if (msg.userType == this.msgFromMap.user && msg.isRead == this.msgReadMap.no) {
                  logIds.push(msg.charLogId);
                }
              }
              let oldMsgList = customer.msgList;
              customer.msgList = [...msgList, ...oldMsgList];
              if (logIds.length > 0) {
                this.request_update_log_state(logIds);
              }
            } else {
              customer.noMoreMsg = true;
            }
          })
          .catch((err) => {
            this.loadingMsg = false;
            this.showWarn(err.message);
          });
      } else {
        this.loadingMsg = false;
      }
    },
    get_msg_file(file) {
      this.showLoading();
      ossUpload({
        file: file,
        type: file_type_map.service
      })
        .then((res) => {
          this.send_img(res);
          this.hideLoading();
        })
        .catch((err) => {
          this.hideLoading();
          this.showWarn(err.message);
        });
    },
    /**************请求*****************/
    request_service_info() {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/common/getCustomerService'
        })
          .then((res) => {
            if (res.data.success) {
              resolve(res.data.data || null);
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((err) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    request_customer_list() {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/im/manage/chat'
        })
          .then((res) => {
            if (res.data.success) {
              resolve(res.data.data || []);
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((err) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    request_msg_template(serviceId) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/im/manage/chat/getQuickReply',
          params: {
            customerServiceId: serviceId
          }
        })
          .then((res) => {
            if (res.data.success) {
              resolve(res.data.data || []);
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((err) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    request_user_info(userId) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/im/manage/chat/getUserInfo',
          params: {
            userId: userId
          }
        })
          .then((res) => {
            if (res.data.success) {
              resolve(res.data.data || {});
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((err) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    request_chat_log({ serviceId, userId, lastChatLogId } = {}) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/im/manage/chat/getChatLogList',
          params: {
            customerServiceId: serviceId,
            userId: userId,
            lastChatLogId: lastChatLogId,
            loadedNumber: 3
          }
        })
          .then((res) => {
            if (res.data.success) {
              resolve(res.data.data || []);
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((err) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    request_update_log_state(logIds) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/im/manage/chat/modifyChatLogReadStatus',
          method: 'post',
          data: {
            chatLogIdList: logIds
          }
        })
          .then((res) => {
            if (res.data.success) {
              resolve();
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((err) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    }
  }
};
