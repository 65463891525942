export default {
  data() {
    return {
      positionMap: {
        left: 1,
        right: 2,
        center: 3
      },
      msgTypeMap: {
        text: 3,
        img: 2,
        system: 1,
        close: 5,
        evaluate: 4
      },
      lineBreak: '↵',
      msgFromMap: {
        user: 1, //用户
        service: 2, //客服
        system: 3 //系统
      },
      msgReadMap: {
        yes: 1,
        no: 0
      }
    };
  },
  methods: {
    initMsg(msg) {
      let content,
        position,
        headImgUrl = '';
      let msgTypeMap = this.msgTypeMap;
      let positionMap = this.positionMap;
      let msgFromMap = this.msgFromMap;
      if (msg.msgType === msgTypeMap.text || msg.msgType === msgTypeMap.system) {
        content = msg.content;
        content = this.insertLineBreak(content);
        content = this.imgTextToImg(content, this.emojiList);
      } else if (msg.msgType === msgTypeMap.img) {
        content = {
          url: this.imgBase + msg.resUrl
        };
      }
      switch (Number(msg.userType)) {
        case msgFromMap.user:
          position = positionMap.left;
          headImgUrl = msg.userHeadImgUrl;
          break;
        case msgFromMap.service:
          position = positionMap.right;
          headImgUrl = this.imgBase + msg.customerServiceHeadImgUrl;
          break;
        case msgFromMap.system:
          position = positionMap.center;
          break;
      }
      return {
        content: content,
        position: position,
        headImgUrl: headImgUrl,
        type: msg.msgType,
        chatLogId: msg.charLogId
      };
    },
    insertLineBreak(str) {
      let re = new RegExp(this.lineBreak, 'g');
      return str.replace(re, '<br>');
    },
    imgTextToImg(str, emojiList) {
      let imgTextArr = str.match(/\[.+?\]/g) || [];
      imgTextArr = [...new Set(imgTextArr)];
      if (imgTextArr.length > 0) {
        for (let imgText of imgTextArr) {
          let emoji = null;
          emojiList.findIndex((item) => {
            if (item.title === imgText) {
              emoji = item;
              return true;
            }
          });
          if (emoji) {
            let text = imgText.slice(1, imgText.length - 1);
            let re = new RegExp(`\\[${text}\\]`, 'g');
            str = str.replace(re, `<img src="${emoji.src}" class="emoji"/>`);
          }
        }
      }
      return str;
    },
    request_emoji_list() {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/im/manage/chat/getEmoticon'
        })
          .then((res) => {
            if (res.data.success) {
              resolve(res.data.data || []);
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((err) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    }
  }
};
