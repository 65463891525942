var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content_img" }, [
    _c("img", {
      attrs: { src: _vm.url + _vm.zipRuler, alt: "" },
      on: { click: _vm.check_img },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }