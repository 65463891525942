var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "frame" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "Clickoutside",
            rawName: "v-Clickoutside",
            value: _vm.handle_close,
            expression: "handle_close",
          },
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        staticClass: "chat_frame_com",
      },
      [
        _c(
          "div",
          { staticClass: "frame_left" },
          [
            _c("frame-left", {
              attrs: { data: _vm.customerList, mainColor: _vm.mainColor },
              on: { toggleChatPerson: _vm.toggle_chat_person },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.userId,
                expression: "userId",
              },
            ],
            staticClass: "frame_center",
          },
          [
            _c(
              "div",
              {
                staticClass: "top_title",
                style: { backgroundColor: _vm.mainColor },
              },
              [
                _c("div", { staticClass: "user_name_wrap" }, [
                  _vm._v(_vm._s(_vm.userInfo.nickname)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "end_wrap" }, [
                  _c("button", { on: { click: _vm.close_chat } }, [
                    _vm._v("结束对话"),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "top-fresh",
                    rawName: "v-top-fresh",
                    value: _vm.load,
                    expression: "load",
                  },
                ],
                ref: "chatLog",
                staticClass: "chat_log scroll_bar",
                attrs: { "scroll-disabled": _vm.scrollDisabled },
              },
              [
                _c("div", { staticClass: "load_tip" }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.loadingMsg,
                          expression: "loadingMsg",
                        },
                      ],
                    },
                    [_vm._v("加载中")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.loadingMsg && !_vm.noMoreMsg,
                          expression: "!loadingMsg && !noMoreMsg",
                        },
                      ],
                      on: { click: _vm.load },
                    },
                    [_vm._v("加载更多")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.noMoreMsg,
                          expression: "noMoreMsg",
                        },
                      ],
                    },
                    [_vm._v("没有更多消息")]
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.msgList, function (msg) {
                  return _c("message", {
                    key: msg.chatLogId,
                    attrs: {
                      msg: msg,
                      positionMap: _vm.positionMap,
                      msgTypeMap: _vm.msgTypeMap,
                    },
                  })
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tool_bar" }, [
              _c("img", {
                staticClass: "tool_item",
                attrs: {
                  src: require("../../assets/image/service/service_2.png"),
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    _vm.show_emoji_list = !_vm.show_emoji_list
                  },
                },
              }),
              _vm._v(" "),
              _c("img", {
                staticClass: "tool_item",
                attrs: {
                  src: require("../../assets/image/service/service_3.png"),
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    _vm.$refs.msg_file.click()
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.show_emoji_list,
                      expression: "show_emoji_list",
                    },
                    {
                      name: "Clickoutside",
                      rawName: "v-Clickoutside",
                      value: _vm.hide_emoji,
                      expression: "hide_emoji",
                    },
                  ],
                  staticClass: "eomji_list scroll_bar",
                },
                _vm._l(_vm.emojiList, function (emoji) {
                  return _c("img", {
                    staticClass: "emoji_item",
                    attrs: { src: emoji.src, title: emoji.title, alt: "" },
                    on: {
                      click: function ($event) {
                        _vm.insert_emoji(emoji.src, emoji.title)
                      },
                    },
                  })
                })
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "chat_input_com scroll_bar" }, [
              _c("div", {
                ref: "input_box",
                staticClass: "input_box",
                attrs: { contenteditable: "true" },
                on: {
                  keydown: _vm.input_key_down,
                  keyup: _vm.input_key_up,
                  blur: _vm.input_blur,
                  paste: _vm.paste_text,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.show_place_holder,
                      expression: "show_place_holder",
                    },
                  ],
                  staticClass: "place_holder",
                  on: { click: _vm.start_input },
                },
                [_vm._v("\n          输入消息...\n        ")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "send_wrap" }, [
              _c(
                "button",
                { staticClass: "send_btn", on: { click: _vm.send } },
                [_vm._v("发送")]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.userId,
                expression: "userId",
              },
            ],
            staticClass: "frame_right",
          },
          [
            _c("frame-right", {
              attrs: {
                mainColor: _vm.mainColor,
                msgTemplate: _vm.msgTemplate,
                userInfo: _vm.userInfo,
              },
              on: {
                hideFrame: _vm.handle_close,
                getFastMsg: _vm.get_fast_msg,
                send: _vm.send_fast_msg,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "close_wrap" }, [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.userId,
                expression: "!userId",
              },
            ],
            staticClass: "close",
            attrs: { src: require("../../assets/image/close.png"), alt: "" },
            on: {
              click: function ($event) {
                _vm.$emit("hide")
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("input", {
          ref: "msg_file",
          staticStyle: { display: "none" },
          attrs: { type: "file", accept: "image/*" },
          on: { change: _vm.get_msg_file },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }