var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "text_content",
      style: { backgroundColor: _vm.mainColor, color: _vm.fontColor },
    },
    [_c("p", { ref: "content", domProps: { innerHTML: _vm._s(_vm.content) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }