<template>
  <transition name="frame">
    <div class="chat_frame_com" v-Clickoutside="handle_close" v-show="visible">
      <div class="frame_left">
        <frame-left
          :data="customerList"
          :mainColor="mainColor"
          @toggleChatPerson="toggle_chat_person"
        ></frame-left>
      </div>
      <div class="frame_center" v-show="userId">
        <div class="top_title" :style="{ backgroundColor: mainColor }">
          <div class="user_name_wrap">{{ userInfo.nickname }}</div>
          <div class="end_wrap">
            <button @click="close_chat">结束对话</button>
          </div>
        </div>
        <div
          class="chat_log scroll_bar"
          v-top-fresh="load"
          :scroll-disabled="scrollDisabled"
          ref="chatLog"
        >
          <div class="load_tip">
            <span v-show="loadingMsg">加载中</span>
            <span v-show="!loadingMsg && !noMoreMsg" @click="load">加载更多</span>
            <span v-show="noMoreMsg">没有更多消息</span>
          </div>
          <message
            v-for="msg in msgList"
            :msg="msg"
            :positionMap="positionMap"
            :msgTypeMap="msgTypeMap"
            :key="msg.chatLogId"
          >
          </message>
        </div>
        <div class="tool_bar">
          <img
            src="../../assets/image/service/service_2.png"
            alt=""
            class="tool_item"
            @click="show_emoji_list = !show_emoji_list"
          />
          <img
            src="../../assets/image/service/service_3.png"
            alt=""
            class="tool_item"
            @click="$refs.msg_file.click()"
          />
          <div class="eomji_list scroll_bar" v-show="show_emoji_list" v-Clickoutside="hide_emoji">
            <img
              v-for="emoji in emojiList"
              :src="emoji.src"
              :title="emoji.title"
              alt=""
              class="emoji_item"
              @click="insert_emoji(emoji.src, emoji.title)"
            />
          </div>
        </div>
        <div class="chat_input_com scroll_bar">
          <div
            class="input_box"
            contenteditable="true"
            @keydown="input_key_down"
            @keyup="input_key_up"
            @blur="input_blur"
            @paste="paste_text"
            ref="input_box"
          ></div>
          <div class="place_holder" v-show="show_place_holder" @click="start_input">
            输入消息...
          </div>
        </div>
        <div class="send_wrap">
          <button @click="send" class="send_btn">发送</button>
        </div>
      </div>
      <div class="frame_right" v-show="userId">
        <frame-right
          :mainColor="mainColor"
          :msgTemplate="msgTemplate"
          :userInfo="userInfo"
          @hideFrame="handle_close"
          @getFastMsg="get_fast_msg"
          @send="send_fast_msg"
        >
        </frame-right>
      </div>
      <div class="close_wrap">
        <img
          src="../../assets/image/close.png"
          alt=""
          class="close"
          v-show="!userId"
          @click="$emit('hide')"
        />
      </div>
      <input
        type="file"
        accept="image/*"
        ref="msg_file"
        @change="get_msg_file"
        style="display: none"
      />
    </div>
  </transition>
</template>
<script>
import ChatInput from './ChatInput.vue';
import FrameRight from './FrameRight.vue';
import FrameLeft from './FrameLeft.vue';
import MsgLeftText from './msg/MsgLeftText.vue';
import MsgLeftImg from './msg/MsgLeftImg.vue';
import MsgRightImg from './msg/MsgRightImg.vue';
import MsgRightText from './msg/MsgRightText.vue';
import Message from './Message.vue';
import cursorPosition from '@/mixins/cursorPosition';
import Clickoutside from '@/js/clickoutside';
export default {
  mixins: [],
  props: {
    visible: {
      type: Boolean
    },
    emojiList: {
      type: Array
    },
    positionMap: {
      type: Object
    },
    msgTypeMap: {
      type: Object
    },
    msgList: {
      type: Array
    },
    loadingMsg: {
      type: Boolean,
      default: false
    },
    noMoreMsg: {
      type: Boolean,
      default: false
    },
    customerList: {
      type: Array,
      default() {
        return [];
      }
    },
    msgTemplate: {
      type: Array,
      default() {
        return [];
      }
    },
    userId: {
      type: String
    },
    userInfo: {
      type: Object
    },
    mainColor: {
      type: String,
      default: '#e5e5e5'
    }
  },
  components: {
    'chat-input': ChatInput,
    'frame-right': FrameRight,
    'frame-left': FrameLeft,
    'msg-left-text': MsgLeftText,
    'msg-left-img': MsgLeftImg,
    'msg-right-text': MsgRightText,
    'msg-right-img': MsgRightImg,
    message: Message
  },
  directives: { Clickoutside },
  data() {
    return {
      range: null,
      show_emoji_list: false,
      ctrKey: false,
      keyCode: {
        enter: 13,
        ctrl: 17
      },
      show_place_holder: true,
      lineBreak: '↵'
    };
  },
  created() {},
  computed: {
    scrollDisabled() {
      return this.loadingMsg || this.noMoreMsg;
    }
  },
  methods: {
    hide_emoji() {
      this.show_emoji_list = false;
    },
    handle_close() {
      this.$emit('update:visible', false);
    },
    paste_text(e) {
      let event = window.event || e;
      let text = e.clipboardData.getData('text/plain');
      if (text) {
        text = text.replace(/\s | \n/g, '');
        this.addText(text);
      }
      event.preventDefault();
    },
    toggle_chat_person(userId) {
      this.$emit('toggleChatPerson', userId);
    },
    addText(text) {
      let range = cursorPosition.setCursorPosition(this.$refs.input_box, this.range);
      let imgDom = document.createTextNode(text);
      if (!range) {
        let sel = window.getSelection();
        if (sel && sel.rangeCount === 1 && sel.isCollapsed) {
          range = sel.getRangeAt(0);
        }
      }
      if (range) {
        range.insertNode(imgDom);
        range.collapse(false);
      } else {
        document.execCommand('insertText', 'false', text);
      }
    },
    get_fast_msg(msg) {
      this.$refs.input_box.innerHTML = '';
      this.start_input();
      this.addText(msg);
    },
    load() {
      this.$emit('moreMessage');
    },
    formatInput(str) {
      str = str.replace(/<.{0,1}br.{0,1}>/g, this.lineBreak);
      let $dom = document.createElement('div');
      $dom.innerHTML = str;
      let $imgs = $dom.getElementsByTagName('img');
      let imgArr = [...new Set($imgs)];
      for (let $img of imgArr) {
        let title = $img.getAttribute('data-title');
        let newDom = document.createElement('span');
        newDom.innerHTML = title;
        $dom.replaceChild(newDom, $img);
      }
      str = $dom.innerHTML;
      str = str.replace(/<span>/g, '');
      str = str.replace(/<\/span>/g, '');
      return str;
    },
    insert_emoji(src, title) {
      let range = cursorPosition.setCursorPosition(this.$refs.input_box, this.range);
      let imgDom = document.createElement('img');
      imgDom.src = src;
      imgDom.setAttribute('data-title', title);
      imgDom.setAttribute('class', 'emoji');
      if (!range) {
        let sel = window.getSelection();
        if (sel && sel.rangeCount === 1 && sel.isCollapsed) {
          range = sel.getRangeAt(0);
        }
      }
      if (range) {
        range.insertNode(imgDom);
        range.collapse(false);
      } else {
        document.execCommand(
          'insertHtml',
          'false',
          `<img src="${src}" data-title="${title}" class="emoji"/>`
        );
      }
      this.show_place_holder = false;
    },
    input_blur() {
      try {
        let obj = cursorPosition.getCursorPosition(this.$refs.input_box);
        this.range = obj;
      } catch (err) {}
      if (this.$refs.input_box.innerHTML == '') {
        this.show_place_holder = true;
      }
    },
    send() {
      let msg = this.$refs.input_box.innerHTML;
      if (msg != '') {
        msg = this.formatInput(msg);
        this.$emit('send', { content: msg });
        this.$refs.input_box.innerHTML = '';
      }
    },
    scrollToBottom() {
      let $chatLog = this.$refs.chatLog;
      let offsetHeight = $chatLog.offsetHeight;
      let scrollHeight = $chatLog.scrollHeight;
      $chatLog.scrollTop = scrollHeight - offsetHeight;
    },
    send_fast_msg(msg) {
      this.$emit('send', { content: msg });
    },
    insertBr() {
      let sel = window.getSelection();
      if (sel && sel.rangeCount == 1 && sel.isCollapsed) {
        let range = sel.getRangeAt(0);
        let br = document.createElement('br');
        range.insertNode(br);
        range.collapse(false);
      } else {
        document.execCommand('insertHtml', 'false', '<br>');
      }
    },
    input_key_down(e) {
      let event = e || window.event;
      if (event.keyCode == this.keyCode.enter && this.ctrlKey) {
        this.insertBr();
        this.insertBr();
        event.preventDefault();
      } else if (event.keyCode == this.keyCode.enter && !this.ctrlKey) {
        this.send();
        event.preventDefault();
      } else if (event.keyCode == this.keyCode.ctrl) {
        this.ctrlKey = true;
      }
    },
    input_key_up(e) {
      let event = e || window.event;
      if (event.keyCode != this.keyCode.ctrl) {
        this.ctrlKey = false;
      }
    },
    start_input() {
      this.$refs.input_box.focus();
      this.show_place_holder = false;
    },
    get_msg_file(e) {
      let files = e.target.files;
      if (files.length == 0) {
        return;
      }
      let file = files[0];
      e.target.value = '';
      this.$emit('msgFile', file);
    },
    close_chat() {
      this.$emit('closeChat');
    }
  }
};
</script>
<style lang="scss">
.frame-enter-active {
  animation: frame-out 0.7s;
}
.frame-leave-active {
  animation: frame-out 0.7s reverse;
}
@keyframes frame-out {
  0% {
    transform: translate(100%);
  }
  100% {
    transform: translate(0);
  }
}
.chat_frame_com {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 930px;
  height: 632px;
  background-color: #fff;
  display: flex;
  box-shadow: 0 0 8px #666;
  border-radius: 6px;
}
.frame_left {
  width: 200px;
  box-sizing: border-box;
  border-right: 1px solid #666;
}
.frame_center {
  width: 500px;
  box-sizing: border-box;
}
.frame_right {
  box-sizing: border-box;
  width: 230px;
  border-left: 1px solid #666;
}
.top_title {
  height: 40px;
  position: relative;
  .user_name_wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .end_wrap {
    position: absolute;
    right: 20px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
.chat_log {
  height: 400px;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 10px;
  overflow-y: auto;
  background-color: #f1f1f1;
  .load_tip {
    height: 50px;
    line-height: 50px;
    color: #999;
    text-align: center;
  }
}
.chat_input_com {
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  .place_holder {
    position: absolute;
    left: 0;
    top: 0;
    padding: 8px 10px;
    width: 100%;
    height: 80%;
    color: #999;
  }
  .input_box {
    padding: 8px 10px;
    cursor: text;
    outline: none;
    line-height: 16px;
    .emoji {
      width: 24px;
      height: 24px;
      vertical-align: middle;
      display: inline-block;
    }
  }
}
.send_wrap {
  padding: 0 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .send_btn {
    width: 58px;
    height: 24px;
    box-sizing: border-box;
  }
}
.tool_bar {
  padding: 0 20px;
  height: 40px;
  box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  position: relative;
  .tool_item {
    height: 24px;
    width: auto;
    margin-right: 10px;
    cursor: pointer;
  }
  .eomji_list {
    padding: 10px;
    position: absolute;
    left: 0;
    top: -200px;
    width: 100%;
    height: 200px;
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    overflow-y: auto;
    .emoji_item {
      float: left;
      width: 24px;
      height: 24px;
      margin: 0 12px 12px 0;
      cursor: pointer;
      &:nth-of-type(13n) {
        margin-right: 0;
      }
    }
  }
}
.close_wrap {
  position: absolute;
  right: 12px;
  top: 0;
  height: 40px;
  display: flex;
  align-items: center;
  .close {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
</style>
