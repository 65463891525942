var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "frame_left_com" }, [
    _c(
      "div",
      { staticClass: "top_title", style: { backgroundColor: _vm.mainColor } },
      [_vm._v("--对话列表--")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "user_list scroll_bar" },
      _vm._l(_vm.data, function (user, index) {
        return _c("user-item", {
          key: user.userId,
          attrs: {
            name: user.name,
            notRead: user.notRead,
            content: user.content,
            active: user.active,
            userId: user.userId,
          },
          on: { select: _vm.toggle_chat_person },
        })
      })
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }