<template>
  <div class="page_lost_com">
    <div class="lost-tip">
      <span>因系统更新导致资源加载失败，</span>
      <el-button type="primary" @click="handleReload">刷新页面</el-button>
    </div>
  </div>
</template>
<script>
import { Button } from 'element-ui';
export default {
  mixins: [],
  components: {
    'el-button': Button
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    handleReload() {
      window.location.reload();
    }
  }
};
</script>
<style lang="scss" scoped>
.page_lost_com {
  text-align: center;
  line-height: 100px;
  color: #ff00ff;
  padding: 30px;
}
.lost-tip {
  color: #ff00ff;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
