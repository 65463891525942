/**
 * Created by LSF on 2017/11/13.
 */
import 'core-js';
import 'regenerator-runtime/runtime';
import '../../assets/style/reset.css';
import '../../assets/style/alifont.css';
import '../../assets/style/base.scss';
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/index';
import storeObj from '../../store/store';
import notify from '../../js/notify';
import Vuex from 'vuex';
import VueTour from 'vue-tour'
import { Message, MessageBox, Notification, Loading } from 'element-ui';
import hmConfirm from '../../components/confirm';
const commonConfig = require('../../config/common');
require('vue-tour/dist/vue-tour.css')
require('../../js/httpRequest');
//自定义指令
require('../../js/vue-directive.js');
//自定义过滤器
require('../../js/vue-filter.js');
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(Loading.directive);
Vue.use(VueTour)
const store = new Vuex.Store(storeObj);
let { apiBase, businessDomain, webUrl, imgBase, yxtOss } = commonConfig;
Vue.prototype.apiBase = apiBase;
Vue.prototype.businessDomain = businessDomain;
Vue.prototype.webUrl = webUrl;
Vue.prototype.imgBase = imgBase;
Vue.prototype.yxtOss = yxtOss;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$confirm2 = hmConfirm;
Vue.prototype.$notify = Notification;
const router = new VueRouter({
  routes
});
/*router.beforeResolve((to,from,next) => {
    if(from.path == "/orderdetail")
    {
    }
    next();
})*/
import _ from 'lodash';
const vm = new Vue({
  router,
  store,
  el: '#app',
  created() {
    let width = document.documentElement.clientWidth;
    let height = document.documentElement.clientHeight;
    this.$store.dispatch('window_resize', {
      w: width,
      h: height
    });
    this.window_resize();
  },
  mounted() {
    notify.init();
  },
  methods: {
    window_resize() {
      window.onresize = _.debounce(() => {
        let width = document.documentElement.clientWidth;
        let height = document.documentElement.clientHeight;
        this.$store.dispatch('window_resize', {
          w: width,
          h: height
        });
      }, 50);
    }
  }
});
