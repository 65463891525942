var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar_container" },
    [
      _c(
        "div",
        { staticClass: "left" },
        _vm._l(_vm.menu, function (menuItem) {
          return _c(
            "div",
            {
              staticClass: "menu_item",
              class: { active: menuItem.id == _vm.firstActiveId },
              on: {
                click: function ($event) {
                  _vm.select_first_menu(menuItem)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "nameWrap",
                  style: {
                    backgroundColor:
                      menuItem.id == _vm.firstActiveId ? _vm.colorOne : "",
                  },
                },
                [_vm._v("\n        " + _vm._s(menuItem.name) + "\n      ")]
              ),
            ]
          )
        })
      ),
      _vm._v(" "),
      _vm.active_menu
        ? _c(
            "div",
            { staticClass: "right bg_f" },
            [
              _c("lf-menu", {
                attrs: {
                  menu: _vm.active_menu.subMenu,
                  openIds: _vm.openIds,
                  colorOne: _vm.colorOne,
                  activeId: _vm.activeId,
                },
                on: { select: _vm.menuItemClick },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.isAuthTipVisible },
          on: {
            "update:visible": function ($event) {
              _vm.isAuthTipVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "center", "font-size": "20px" } },
            [
              _vm._v(
                "\n      您当前账号未购买对应功能。了解详情请联系所在区域销售经理或添加客服微信：\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "20px" } },
            [
              _c("img", {
                staticStyle: { width: "360px", height: "auto" },
                attrs: { src: require("../assets/image/kefu_wechat.jpg") },
              }),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }