var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page_lost_com" }, [
    _c(
      "div",
      { staticClass: "lost-tip" },
      [
        _c("span", [_vm._v("因系统更新导致资源加载失败，")]),
        _vm._v(" "),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.handleReload } },
          [_vm._v("刷新页面")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }