var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return true
    ? _c(
        "div",
        {
          staticClass: "avatar",
          style: { backgroundColor: _vm.mainColor, borderColor: _vm.mainColor },
        },
        [_c("img", { attrs: { src: _vm.avatar, alt: "" } })]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }