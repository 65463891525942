export const vip_level_map = {
  default: {
    value: '',
    name: '普通用户'
  },
  ready: {
    value: 0,
    name: '预备会员'
  },
  silver: {
    value: 1,
    name: '银卡会员'
  },
  gold: {
    value: 2,
    name: '金卡会员'
  },
  diamond: {
    value: 3,
    name: '钻石会员'
  }
};
export const vip_level_name = function (level) {
  let name = '未知';
  for (let key in vip_level_map) {
    let vipLevel = vip_level_map[key];
    if (vipLevel.value == level) {
      name = vipLevel.name;
      break;
    }
  }
  return name;
};
