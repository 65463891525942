import lazyRoute from '@/router/lazyRoute';
import { customMenuMap } from '../../js/menu';
const GoodManage = () => {
  lazyRoute.show();
  return import('@/vuePage/good/GoodManage.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const TimeManage = () => {
  lazyRoute.show();
  return import('@/vuePage/good/TimeManage.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const TimeSet = () => {
  lazyRoute.show();
  return import('@/vuePage/good/TimeSet.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const PhotoProduct = () => {
  lazyRoute.show();
  return import('@/vuePage/good/PhotoProduct.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const PhotoPublish = () => {
  lazyRoute.show();
  return import('@/vuePage/good/PhotoPublish.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const PhotoRuler = () => {
  lazyRoute.show();
  return import('@/vuePage/good/PhotoRuler.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const AddYp = () => {
  lazyRoute.show();
  return import('@/vuePage/good/Addyp.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const FrameAlbum = () => {
  lazyRoute.show();
  return import('@/vuePage/good/FrameAlbum.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const AddFrame = () => {
  lazyRoute.show();
  return import('@/vuePage/good/AddFrame.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const GeneralProduct = () => {
  lazyRoute.show();
  return import('@/vuePage/good/GeneralProduct.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const GeneralPublish = () => {
  lazyRoute.show();
  return import('@/vuePage/good/GeneralPublish.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const offlinePublish = () => {
  lazyRoute.show();
  return import('@/vuePage/offline/offlinePublish.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const OfflineGeneral = () => {
  lazyRoute.show();
  return import('@/vuePage/offline/OfflineGeneral.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const OfflineYpPublish = () => {
  lazyRoute.show();
  return import('@/vuePage/offline/OfflineYpPublish.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const OfflineYp = () => {
  lazyRoute.show();
  return import('@/vuePage/offline/OfflineYp.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const GoodsGroupList = () => {
  lazyRoute.show();
  return import('@/vuePage/good/GoodsGroupList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const GoodsGroupDetail = () => {
  lazyRoute.show();
  return import('@/vuePage/good/group/GoodsGroupDetail.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};

export default [
  {
    path: 'offlineYp',
    name: 'offlineYp',
    meta: {
      menuId: customMenuMap.offlineYpProduct
    },
    component: OfflineYp
  },
  {
    path: 'offlineYpPublish',
    name: 'offlineYpPublish',
    meta: {
      menuId: customMenuMap.offlineYpProduct
    },
    component: OfflineYpPublish
  },
  {
    path: 'offlineGeneral',
    name: 'offlineGeneral',
    meta: {
      menuId: customMenuMap.offlineGeneralProduct
    },
    component: OfflineGeneral
  },
  {
    path: 'offlinePublish',
    name: 'offlinePublish',
    meta: {
      menuId: customMenuMap.offlineGeneralProduct
    },
    component: offlinePublish
  },
  {
    path: 'goodmanage',
    name: 'goodmanage',
    meta: {
      menuId: customMenuMap.ypProduct
    },
    component: GoodManage
  },
  {
    path: 'timemanage',
    name: 'timemanage',
    meta: {
      menuId: customMenuMap.ypTimeManage
    },
    component: TimeManage
  },
  {
    path: 'timeset',
    name: 'timeset',
    meta: {
      menuId: customMenuMap.ypTimeManage
    },
    component: TimeSet
  },
  {
    path: 'photoProduct',
    name: 'photoProduct',
    meta: {
      menuId: customMenuMap.photoProduct
    },
    component: PhotoProduct
  },
  {
    path: 'photoPublish',
    name: 'photoPublish',
    meta: {
      menuId: customMenuMap.photoProduct
    },
    component: PhotoPublish
  },
  {
    path: 'photoRuler',
    name: 'photoRuler',
    meta: {
      menuId: customMenuMap.photoProduct
    },
    component: PhotoRuler
  },
  {
    path: 'addYp',
    name: 'addYp',
    meta: {
      menuId: customMenuMap.ypProduct
    },
    component: AddYp
  },
  {
    path: 'frameAlbum',
    name: 'frameAlbum',
    meta: {
      menuId: customMenuMap.frameAlbumProduct
    },
    component: FrameAlbum
  },
  {
    path: 'addFrame',
    name: 'addFrame',
    meta: {
      menuId: customMenuMap.frameAlbumProduct
    },
    component: AddFrame
  },
  {
    path: 'generalProduct',
    name: 'generalProduct',
    meta: {
      menuId: customMenuMap.generalProduct
    },
    component: GeneralProduct
  },
  {
    path: 'generalPublish',
    name: 'generalPublish',
    meta: {
      menuId: customMenuMap.generalProduct
    },
    component: GeneralPublish
  },
  {
    path: 'goodsGroupList',
    name: 'goodsGroupList',
    meta: {
      menuId: customMenuMap.goodsGroupList
    },
    component: GoodsGroupList
  },
  {
    path: 'goodsGroupDetail',
    name: 'goodsGroupDetail',
    meta: {
      menuId: customMenuMap.goodsGroupList
    },
    component: GoodsGroupDetail
  }
];
