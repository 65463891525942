/**
 * Created by lsf on 2018/8/19.
 */
export default {
  methods: {
    initStore(store) {
      let selectedStore = -1;
      if (store.length == 1) {
        selectedStore = store[0].storeId;
      } else {
        store.unshift({
          storeId: -1,
          storeName: '全部门店'
        });
      }
      return {
        store: store,
        selectedStore: selectedStore
      };
    },
    initStore2({ store = [], allStoreId = '' } = {}) {
      let selectedStore = allStoreId;
      if (store.length == 1) {
        selectedStore = store[0].storeId;
      } else {
        store.unshift({
          storeId: allStoreId,
          storeName: '全部门店'
        });
      }
      return {
        store: store,
        selectedStore: selectedStore
      };
    },
    //获取管理门店
    get_manage_store() {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/company/getStoreList'
        })
          .then((res) => {
            if (res.data.success) {
              let result = res.data.data;
              let store = [];
              result.forEach((item) => {
                store.push({
                  storeId: item.storeId,
                  storeName: item.storeName,
                  phone: item.phone,
                  address: item.address,
                  imgUrl: item.imgUrl
                });
              });
              resolve(store);
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((res) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    // 请求门店信息
    request_store_info({ storeId } = {}) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/setup/store/manage/get',
          params: {
            storeId: storeId
          }
        })
          .then((res) => {
            if (res.data.success) {
              resolve(res.data.data);
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((err) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    // 编辑门店
    request_edit_store(param) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/setup/store/manage/edit',
          method: 'post',
          data: param
        })
          .then((res) => {
            if (res.data.success) {
              resolve();
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((err) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    }
  }
};
