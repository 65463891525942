import lazyRoute from '@/router/lazyRoute';
import { customMenuMap } from '../../js/menu';
const RechargeActivity = () => {
  lazyRoute.show();
  return import('@/vuePage/vip/RechargeActivity.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const EditRecharge = () => {
  lazyRoute.show();
  return import('@/vuePage/vip/EditRecharge.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const Djq = () => {
  lazyRoute.show();
  return import('@/vuePage/discount/Djq.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const YxqDiscount = () => {
  lazyRoute.show();
  return import('@/vuePage/discount/YxqDiscount.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const PayDiscount = () => {
  lazyRoute.show();
  return import('@/vuePage/discount/PayDiscount.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const LuckDiscount = () => {
  lazyRoute.show();
  return import('@/vuePage/discount/LuckDiscount.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const DiscountList = () => {
  lazyRoute.show();
  return import('@/vuePage/discount/DiscountList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const DiscountProducts = () => {
  lazyRoute.show();
  return import('@/vuePage/discount/DiscountProducts.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const AddGroupBooking = () => {
  lazyRoute.show();
  return import('@/vuePage/group/AddGroupBooking.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const GroupBooking = () => {
  lazyRoute.show();
  return import('@/vuePage/group/GroupBooking.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const VideoList = () => {
  lazyRoute.show();
  return import('@/vuePage/video/VideoList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};

export default [
  {
    path: 'groupBooking',
    name: 'groupBooking',
    meta: {
      menuId: customMenuMap.groupBuyManage
    },
    component: GroupBooking
  },
  {
    path: 'addGroupBooking',
    name: 'addGroupBooking',
    meta: {
      menuId: customMenuMap.groupBuyManage
    },
    component: AddGroupBooking
  },
  {
    path: 'rechargeActivity',
    name: 'rechargeActivity',
    meta: {
      menuId: customMenuMap.rechargeActivity
    },
    component: RechargeActivity
  },
  {
    path: 'editRecharge',
    name: 'editRecharge',
    meta: {
      menuId: customMenuMap.rechargeActivity
    },
    component: EditRecharge
  },
  {
    path: 'djq',
    name: 'djq',
    meta: {
      menuId: customMenuMap.discountList
    },
    component: Djq
  },
  {
    path: 'yxqDiscount',
    name: 'yxqDiscount',
    meta: {
      menuId: customMenuMap.discountList
    },
    component: YxqDiscount
  },
  {
    path: 'payDiscount',
    name: 'payDiscount',
    meta: {
      menuId: customMenuMap.discountList
    },
    component: PayDiscount
  },
  {
    path: 'luckDiscount',
    name: 'luckDiscount',
    meta: {
      menuId: customMenuMap.discountList
    },
    component: LuckDiscount
  },
  {
    path: 'discountList',
    name: 'discountList',
    meta: {
      menuId: customMenuMap.discountList
    },
    component: DiscountList
  },
  {
    path: 'discountProducts',
    name: 'discountProducts',
    meta: {
      menuId: customMenuMap.discountList
    },
    component: DiscountProducts
  },
  {
    path: 'videoList',
    name: 'videoList',
    meta: {
      menuId: customMenuMap.videoList
    },
    component: VideoList
  }
];
