/**
 * Created by Administrator on 2018/4/25.
 */
import Vue from 'vue';

Vue.directive('enable', {
  inserted(el, binding) {
    let enable = binding.value.enable;
    if (!enable && el.parentNode) {
      el.parentNode.removeChild(el);
    }
  },
  update(el, binding) {
    let enable = binding.value.enable;
    if (!enable && el.parentNode) {
      el.parentNode.removeChild(el);
    }
  }
});

Vue.directive('title', {
  inserted(el, binding) {
    let { value = '' } = binding;
    document.title = value;
  },
  update(el, binding) {
    let { value = '' } = binding;
    document.title = value;
  }
});

Vue.directive('html', {
  inserted(el, binding) {
    let { value = '' } = binding;
    document.html = value;
  },
  update(el, binding) {
    let { value = '' } = binding;
    document.html = value;
  }
});

Vue.directive('bottom-fresh', {
  inserted(el, binding) {
    el.addEventListener('scroll', function () {
      let scrollDisabled = false;
      try {
        scrollDisabled = Boolean(el.getAttribute('scroll-disabled'));
      } catch (err) {}
      let bodyHeight = el.offsetHeight;
      let innerHeight = el.scrollHeight;
      let scrollTop = el.scrollTop;
      if (scrollTop + bodyHeight >= innerHeight - 10) {
        if (!scrollDisabled) {
          binding.value();
        }
      }
    });
  }
});

Vue.directive('top-fresh', {
  inserted(el, binding) {
    console.log(el.scrollHeight);
    el.setAttribute('scroll-height', el.scrollHeight);
    el.addEventListener('scroll', function () {
      let scrollDisabled = false;
      try {
        scrollDisabled = Boolean(el.getAttribute('scroll-disabled'));
      } catch (err) {}
      let scrollTop = el.scrollTop;
      if (scrollTop == 0 && !scrollDisabled) {
        binding.value();
      }
    });
  },
  componentUpdated(el) {
    let scrollHeight = el.scrollHeight;
    let oldScrollHeight = el.getAttribute('scroll-height');
    el.setAttribute('scroll-height', scrollHeight);
    if (scrollHeight > oldScrollHeight) {
      let newTop = scrollHeight - oldScrollHeight;
      if (el.scrollTop < newTop) {
        el.scrollTop = scrollHeight - oldScrollHeight;
      }
    }
  }
});

//图片自适应
Vue.directive('adjust-pic', {
  inserted: function (el, binding) {
    let w = 0;
    let h = 0;
    try {
      w = binding.value.w;
      h = binding.value.h;
    } catch (err) {}
    let img = new Image();
    img.src = el.src;
    img.onload = () => {
      let width = el.width;
      let height = el.height;
      if (w == 0 || h == 0) {
        if (width < height) {
          el.style.width = 'auto';
          el.style.height = '100%';
        } else if (width > height) {
          el.style.width = '100%';
          el.style.height = 'auto';
        } else {
          el.style.width = '100%';
          el.style.height = '100%';
        }
      } else {
        if (width / height >= w / h) {
          el.style.width = '100%';
          el.style.height = 'auto';
        } else if (width / height < w / h) {
          el.style.width = 'auto';
          el.style.height = '100%';
        }
      }
    };
    img.onerror = () => {
      el.style.width = '100%';
      el.style.height = '100%';
    };
  },
  update: function (el, binding) {
    let w = 0;
    let h = 0;
    try {
      w = binding.value.w;
      h = binding.value.h;
    } catch (err) {}
    let img = new Image();
    img.src = el.src;
    img.onload = () => {
      let width = el.width;
      let height = el.height;
      if (w == 0 || h == 0) {
        if (width < height) {
          el.style.width = 'auto';
          el.style.height = '100%';
        } else if (width > height) {
          el.style.width = '100%';
          el.style.height = 'auto';
        } else {
          el.style.width = '100%';
          el.style.height = '100%';
        }
      } else {
        if (width / height >= w / h) {
          el.style.width = '100%';
          el.style.height = 'auto';
        } else if (width / height < w / h) {
          el.style.width = 'auto';
          el.style.height = '100%';
        }
      }
    };
    img.onerror = () => {
      el.style.width = '100%';
      el.style.height = '100%';
    };
  }
});
Vue.directive('positive-integer', {
  update(el, binding) {
    let value = Number(binding.value.value);
    let isValid = true;
    if (isNaN(value)) {
      isValid = false;
    }
    if (isValid) {
      el.style.backgroundColor = 'green';
    } else {
      el.style.backgroundColor = 'red';
    }
  }
});
