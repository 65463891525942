<template>
  <div class="sidebar_container">
    <div class="left">
      <div
        class="menu_item"
        :class="{ active: menuItem.id == firstActiveId }"
        v-for="menuItem in menu"
        @click="select_first_menu(menuItem)"
      >
        <div
          class="nameWrap"
          :style="{ backgroundColor: menuItem.id == firstActiveId ? colorOne : '' }"
        >
          {{ menuItem.name }}
        </div>
      </div>
    </div>
    <div class="right bg_f" v-if="active_menu">
      <lf-menu
        :menu="active_menu.subMenu"
        :openIds="openIds"
        :colorOne="colorOne"
        :activeId="activeId"
        @select="menuItemClick"
      >
      </lf-menu>
    </div>
    <el-dialog title="提示" :visible.sync="isAuthTipVisible">
      <div style="text-align: center; font-size: 20px">
        您当前账号未购买对应功能。了解详情请联系所在区域销售经理或添加客服微信：
      </div>
      <div style="text-align: center; margin-top: 20px">
        <img src="../assets/image/kefu_wechat.jpg" style="width: 360px; height: auto" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui';
import LfMenu from './LfMenu.vue';
import { menuRouterMap } from '../js/menu';
import appMixin from '../mixins/appMixin';
export default {
  mixins: [appMixin],
  props: ['menu', 'colorOne'],
  data() {
    return {
      indexUrl: menuRouterMap,
      activeId: null,
      firstActiveId: null,
      isAuthTipVisible: false
    };
  },
  components: {
    'lf-menu': LfMenu,
    'el-dialog': Dialog
  },
  created() {
    this.init_current_id();
  },
  watch: {
    $route: function () {
      this.init_current_id();
    }
  },
  computed: {
    active_menu() {
      let obj = {};
      this.menu.findIndex((item) => {
        if (item.id == this.firstActiveId) {
          obj = item;
          return true;
        }
      });
      return obj;
    },
    openIds() {
      let ids = [];
      this.menu.findIndex((item) => {
        if (item.id == this.firstActiveId) {
          for (let sub of item.subMenu) {
            ids.push(sub.id);
          }
          return true;
        }
      });
      return ids;
    }
  },
  methods: {
    /******业务代码*******/
    // 点击一级标签
    select_first_menu(item) {
      if (!item.enabled && !item.subMenu.length) {
        this.isAuthTipVisible = true;
        return;
      }
      this.firstActiveId = item.id;
      if (item.subMenu.length == 0) {
        switch (item.url) {
          case 'business/index':
            this.$router.push('/statistics');
            break;
        }
      }
    },
    menuItemClick({ url, id, enabled } = {}) {
      console.log(url);
      if (!enabled) {
        this.isAuthTipVisible = true;
        return;
      }
      let path = '';
      let menuItem = null;
      let urlIndex = this.indexUrl.findIndex((item) => {
        if (item.url == url) {
          path = item.router;
          menuItem = item;
          return true;
        }
      });
      if (urlIndex != -1) {
        if (menuItem.newTab) {
          this.open_window({ path: path });
        } else {
          this.activeId = id;
          this.$router.push(path);
        }
      } else {
        this.$message({
          message: '开发中'
        });
      }
    },
    init_current_id() {
      let url = '';
      let menuId = this.$route.meta.menuId;
      if (menuId == undefined) {
        return;
      }
      this.indexUrl.findIndex((menuItem) => {
        if (menuItem.id == menuId) {
          url = menuItem.url;
          return true;
        }
      });
      if (!url) {
        return;
      }
      console.log(this.menu);
      function get_id(tree, ...ids) {
        let currentMenu = tree;
        ids.forEach((key) => {
          currentMenu = currentMenu.subMenu[key];
        });
        if (currentMenu == undefined) {
          ids.pop();
          ids[ids.length - 1]++;
          return get_id(tree, ...ids);
        } else {
          if (currentMenu.url == url) {
            return ids;
          } else if (currentMenu.subMenu.length) {
            ids.push(0);
            return get_id(tree, ...ids);
          } else if (currentMenu.subMenu.length == 0) {
            ids[ids.length - 1]++;
            return get_id(tree, ...ids);
          }
        }
      }
      let ids = get_id({ subMenu: this.menu }, 0);
      console.log(ids);
      if (ids && ids.length > 0) {
        let menuItem = {
          subMenu: this.menu
        };
        ids.forEach((key, index) => {
          menuItem = menuItem.subMenu[key];
          if (index == 0) {
            this.firstActiveId = menuItem.id;
          }
        });
        this.activeId = menuItem.id;
      }
    }
    /**********逻辑代码**************/
  }
};
</script>

<style lang="scss" scoped>
.sidebar_container {
  width: 230px;
  height: 100%;
  display: flex;
}
.left {
  width: 110px;
  height: 100%;
  background: #595757;
  overflow-y: auto;
  .menu_item {
    height: 60px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .nameWrap {
      width: 90px;
      height: 40px;
      border-radius: 4px;
      text-align: center;
      line-height: 40px;
      color: #fff;
      font-size: 18px;
      user-select: none;
    }
    &.active {
      .nameWrap {
        color: #666;
      }
    }
  }
}
.right {
  width: 120px;
  height: 100%;
  overflow-y: auto;
}
</style>
