<template>
  <div>
    <msg-left-text
      v-if="msg.position == positionMap.left && msg.type == msgTypeMap.text"
      :content="msg.content"
      :avatar="msg.headImgUrl"
    ></msg-left-text>
    <msg-left-img
      v-if="msg.position == positionMap.left && msg.type == msgTypeMap.img"
      :url="msg.content.url"
      :avatar="msg.headImgUrl"
    ></msg-left-img>
    <msg-right-img
      v-if="msg.position == positionMap.right && msg.type == msgTypeMap.img"
      :url="msg.content.url"
      :avatar="msg.headImgUrl"
    ></msg-right-img>
    <msg-right-text
      v-if="msg.position == positionMap.right && msg.type == msgTypeMap.text"
      :content="msg.content"
      :avatar="msg.headImgUrl"
    ></msg-right-text>
    <!--<msg-system v-if="msg.type == msgTypeMap.system"  :content="msg.content"></msg-system>-->
  </div>
</template>
<script>
import MsgLeftText from './msg/MsgLeftText.vue';
import MsgLeftImg from './msg/MsgLeftImg.vue';
import MsgRightImg from './msg/MsgRightImg.vue';
import MsgRightText from './msg/MsgRightText.vue';
import MsgSystem from './msg/MsgSystem.vue';
export default {
  mixins: [],
  props: {
    msg: {
      type: Object
    },
    positionMap: {
      type: Object
    },
    msgTypeMap: {
      type: Object
    }
  },
  components: {
    'msg-left-text': MsgLeftText,
    'msg-left-img': MsgLeftImg,
    'msg-right-text': MsgRightText,
    'msg-right-img': MsgRightImg,
    'msg-system': MsgSystem
  },
  data() {
    return {};
  },
  created() {},
  methods: {}
};
</script>
<style lang="scss" scoped></style>
