import lazyRoute from '@/router/lazyRoute';
import { customMenuMap } from '../../js/menu';
const YxtUpload = () => {
  lazyRoute.show();
  return import('@/vuePage/yxt/YxtUpload.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const YxtOrderList = () => {
  lazyRoute.show();
  return import('@/vuePage/yxt/YxtOrderList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const YxtOrderDetail = () => {
  lazyRoute.show();
  return import('@/vuePage/yxt/YxtOrderDetail.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const YxtHome = () => {
  lazyRoute.show();
  return import('@/vuePage/yxt/YxtHome.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};

export default [
  {
    path: 'yxtHome',
    name: 'yxtHome',
    meta: {
      menuId: customMenuMap.yxtHome
    },
    component: YxtHome
  },
  {
    path: 'yxtOrderDetail',
    name: 'yxtOrderDetail',
    meta: {
      menuId: customMenuMap.yxtOrderManage
    },
    component: YxtOrderDetail
  },
  {
    path: 'yxtOrderList',
    name: 'yxtOrderList',
    meta: {
      menuId: customMenuMap.yxtOrderManage
    },
    component: YxtOrderList
  },
  {
    path: 'yxtUpload',
    name: 'yxtUpload',
    meta: {
      menuId: customMenuMap.yxtSubmitOrder
    },
    component: YxtUpload
  }
];
