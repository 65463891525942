import lazyRoute from '@/router/lazyRoute';
import { customMenuMap } from '../../js/menu';
const ServiceList = () => {
  lazyRoute.show();
  return import('@/vuePage/service/ServiceList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const AddService = () => {
  lazyRoute.show();
  return import('@/vuePage/service/AddService.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const ChatLog = () => {
  lazyRoute.show();
  return import('@/vuePage/service/ChatLog.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const AutoReply = () => {
  lazyRoute.show();
  return import('@/vuePage/service/AutoReply.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};

export default [
  {
    path: 'autoReply',
    name: 'autoReply',
    meta: {
      menuId: customMenuMap.autoReplyManage
    },
    component: AutoReply
  },
  {
    path: 'chatLog',
    name: 'chatLog',
    meta: {
      menuId: customMenuMap.chatLog
    },
    component: ChatLog
  },
  {
    path: 'addService',
    name: 'addService',
    meta: {
      menuId: customMenuMap.serviceManage
    },
    component: AddService
  },
  {
    path: 'serviceList',
    name: 'serviceList',
    meta: {
      menuId: customMenuMap.serviceManage
    },
    component: ServiceList
  }
];
