<template>
  <div>
    <div v-for="menuItem in menu" :key="menuItem.id">
      <lf-sub-menu
        v-if="menuItem.subMenu.length > 0"
        :menuItem="menuItem"
        :openIds="openId"
        :activeId="activeId"
        :colorOne="colorOne"
        @openSub="openSub"
        @closeSub="closeSub"
        @menuItemClick="menuItemClick"
      >
      </lf-sub-menu>
      <lf-menu-item
        v-else
        :menuItem="menuItem"
        @menuItemClick="menuItemClick"
        :activeId="activeId"
        :colorOne="colorOne"
      ></lf-menu-item>
    </div>
  </div>
</template>
<script>
import collapse from '../js/collapse';
import LfSubMenu from './LfSubMenu.vue';
import LfMenuItem from './LfMenuItem.vue';
export default {
  mixins: [],
  props: ['menu', 'openIds', 'colorOne', 'activeId'],
  components: {
    collapse: collapse,
    'lf-sub-menu': LfSubMenu,
    'lf-menu-item': LfMenuItem
  },
  data() {
    return {
      openId: []
    };
  },
  created() {
    this.openId = this.openIds;
  },
  updated() {
    this.openId = this.openIds;
  },
  methods: {
    openSub(id) {
      let isRepeat = this.openId.findIndex((item) => {
        if (id == item) {
          return true;
        }
      });
      if (isRepeat == -1) {
        this.openId.push(id);
      }
    },
    closeSub(id) {
      let isRepeat = this.openId.findIndex((item) => {
        if (id == item) {
          return true;
        }
      });
      if (isRepeat != -1) {
        this.openId.splice(isRepeat, 1);
      }
    },
    menuItemClick(item) {
      this.$emit('select', {
        url: item.url,
        id: item.id,
        enabled: item.enabled
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.test {
  width: 100%;
  height: 200px;
  background-color: #fff;
}
</style>
