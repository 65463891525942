export const file_type_map = {
  yp: 10, //照片回传
  proBanner: 20, //产品图片
  resourceImg: 30, //素材图片
  discountImg: 50, //优惠券图片
  others: 100,
  service: 200,
  video: 25
};

export const MAX_FILE_SIZE = 10 * 1024 * 1024;

export const RESOURCE_TYPE = {
  noJump: {
    id: 0,
    name: '不跳转'
  },
  activity: {
    id: 1,
    name: '活动'
  },
  goods: {
    id: 2,
    name: '商品'
  },
  live: {
    id: 3,
    name: '直播'
  },
  web: {
    id: 4,
    name: '链接'
  }
};

export const XCX_PAGE = {
  recharge: {
    path: '/pages/vip/balanceRecord',
    name: '充值送入口'
  },
  groupList: {
    path: '/pages/group/groupIndex',
    name: '拼团入口'
  },
  offlineIndex: {
    path: '/pages/offline/index',
    name: '到店入口'
  },
  liveAll: {
    path: '/pages/index/live',
    name: '所有直播'
  },
  liveIng: {
    path: '/pages/index/live?status=101',
    name: '正在直播'
  },
  liveAdvance: {
    path: '/pages/index/live?status=102',
    name: '直播预告'
  },
  liveEnd: {
    path: '/pages/index/live?status=103',
    name: '直播回放'
  }
};

export const get_page_name = function (path) {
  let { recharge, groupList, offlineIndex, liveAll, liveIng, liveAdvance, liveEnd } = XCX_PAGE;
  let name = '';
  switch (path) {
    case recharge.path:
      name = recharge.name;
      break;
    case groupList.path:
      name = groupList.name;
      break;
    case offlineIndex.path:
      name = offlineIndex.name;
      break;
    case liveAll.path:
      name = liveAll.name;
      break;
    case liveIng.path:
      name = liveIng.name;
      break;
    case liveAdvance.path:
      name = liveAdvance.name;
      break;
    case liveEnd.path:
      name = liveEnd.name;
      break;
  }
  return name;
};
