<template>
  <div class="msg_item_com">
    <content-text :mainColor="mainColor" :fontColor="fontColor" :content="content"> </content-text>
  </div>
</template>
<script>
import ContentText from './ContentText.vue';
export default {
  mixins: [],
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  components: {
    'content-text': ContentText
  },
  data() {
    return {
      mainColor: '#999',
      fontColor: '#333'
    };
  },
  created() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.msg_item_com {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}
</style>
