import lazyRoute from '@/router/lazyRoute';
import { customMenuMap } from '../../js/menu';
const DistributionSetting = () => {
  lazyRoute.show();
  return import('@/vuePage/distribution/DistributionSetting.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const DistributionOrder = () => {
  lazyRoute.show();
  return import('@/vuePage/distribution/DistributionOrder.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const DistributionMemberList = () => {
  lazyRoute.show();
  return import('@/vuePage/distribution/DistributionMemberList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const DistributionCheckList = () => {
  lazyRoute.show();
  return import('@/vuePage/distribution/DistributionCheckList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const DistributionProduct = () => {
  lazyRoute.show();
  return import('@/vuePage/distribution/DistributionProduct.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
export default [
  {
    path: 'distributionSetting',
    name: 'distributionSetting',
    meta: {
      menuId: customMenuMap.distributionSetting
    },
    component: DistributionSetting
  },
  {
    path: 'distributionOrder',
    name: 'distributionOrder',
    meta: {
      menuId: customMenuMap.distributionOrder
    },
    component: DistributionOrder
  },
  {
    path: 'distributionMemberList',
    name: 'distributionMemberList',
    meta: {
      menuId: customMenuMap.distributionMemberList
    },
    component: DistributionMemberList
  },
  {
    path: 'distributionCheckList',
    name: 'distributionCheckList',
    meta: {
      menuId: customMenuMap.distributionCheckList
    },
    component: DistributionCheckList
  },
  {
    path: 'distributionProduct',
    name: 'distributionProduct',
    meta: {
      menuId: customMenuMap.distributionProduct
    },
    component: DistributionProduct
  }
];
