export const lsPhotoDetailChange = 'photoDetailChange';
export const lsYpDetailChange = 'ypDetailChange';
export const lsYpTimeRulerChange = 'ypTimeRulerChange';
export const lsFrameAlbumDetailChange = 'frameAlbumDetailChange';
export const lsOfflineYpDetailChange = 'offlineYpDetailChange';
export const lsOfflineGeneralDetailChange = 'offlineGeneralDetailChange';
export const lsGeneralDetailChange = 'generalDetailChange';
export const lsYpOrderDetailChange = 'ypOrderDetailChange';
export const lsGeneralOrderDetailChange = 'generalOrderDetailChange';
export const lsOfflineYpOrderDetailChange = 'offlineYpOrderDetailChange';
export const lsOfflineGeneralOrderDetailChange = 'offlineGeneralOrderDetailChange';
export const lsUserDetailChange = 'userDetailChange';
export const lsRechargeActivityChange = 'rechargeActivityChange';
export const lsGroupRulerChange = 'groupRulerChange';
export const lsServiceInfoChange = 'serviceInfoChange';
export const lsStoreDetailChange = 'storeDetailChange';
export const lsNewMessage = "newMessage";