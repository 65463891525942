var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-container" }, [
    _c(
      "div",
      { staticClass: "page-header" },
      [
        _c("top-head", {
          attrs: {
            systemName: _vm.systemName,
            colorOne: _vm.colorOne,
            logo: _vm.imgBase + _vm.logo,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "app_center scroll_bar",
        style: { height: _vm.windowHeight + "px" },
      },
      [
        _c(
          "div",
          { staticClass: "left-menu", class: { menuHide: !_vm.isMenuVisible } },
          [
            _vm.menu.length
              ? _c("side-bar", {
                  attrs: { menu: _vm.menu, colorOne: _vm.colorOne },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "right_content" }, [
          _c("div", { staticClass: "nav-bar" }, [
            _c("div", { staticClass: "bar-left" }, [
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isMenuVisible,
                    expression: "!isMenuVisible",
                  },
                ],
                staticClass: "collapse",
                attrs: { src: require("../assets/image/unfold.png"), alt: "" },
                on: { click: _vm.toggleLeftMenuShow },
              }),
              _vm._v(" "),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isMenuVisible,
                    expression: "isMenuVisible",
                  },
                ],
                staticClass: "collapse",
                attrs: { src: require("../assets/image/fold.png"), alt: "" },
                on: { click: _vm.toggleLeftMenuShow },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "current-nav" }, [
                _vm._v(_vm._s(_vm.pageTitle)),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "bar-right" },
              [
                _c(
                  "hm-button",
                  {
                    staticClass: "right_item fr",
                    attrs: { type: "primary" },
                    on: { click: _vm.history_back },
                  },
                  [
                    _c("i", { staticClass: "iconfont icon-fanhui" }),
                    _vm._v("返回"),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "main-container scroll_bar" },
            [
              _vm.menu.length
                ? _c(
                    "div",
                    { staticClass: "page_content" },
                    [_c("router-view")],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("bottom-foot"),
            ],
            1
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.show_service
      ? _c(
          "div",
          { staticClass: "service_chat_com" },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showChatFrame,
                    expression: "!showChatFrame",
                  },
                ],
                staticClass: "switch_wrap",
              },
              [
                _c("img", {
                  staticClass: "chat_icon",
                  attrs: {
                    src: require("../assets/image/service/service_1.png"),
                    alt: "",
                  },
                  on: { click: _vm.show_chat_frame },
                }),
                _vm._v(" "),
                _c("span", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.newMsg,
                      expression: "newMsg",
                    },
                  ],
                  staticClass: "new_icon",
                }),
              ]
            ),
            _vm._v(" "),
            _c("chat-frame", {
              ref: "chatFrame",
              attrs: {
                visible: _vm.showChatFrame,
                emojiList: _vm.emojiList,
                positionMap: _vm.positionMap,
                msgTypeMap: _vm.msgTypeMap,
                msgList: _vm.msgList,
                loadingMsg: _vm.loadingMsg,
                noMoreMsg: _vm.noMoreMsg,
                customerList: _vm.customerList,
                msgTemplate: _vm.msgTemplate,
                userId: _vm.userId,
                userInfo: _vm.userInfo,
                mainColor: _vm.colorOne,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showChatFrame = $event
                },
                send: _vm.send_msg,
                moreMessage: _vm.moreMessage,
                toggleChatPerson: _vm.toggle_chat_person,
                msgFile: _vm.get_msg_file,
                closeChat: _vm.close_chat,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isWeixin
      ? _c("div", { staticClass: "weixin-tip" }, [
          _c("div", { staticClass: "tip" }, [_vm._v("请使用浏览器打开")]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }