import lazyRoute from '@/router/lazyRoute';
import { customMenuMap } from '../../js/menu';
const TaskList = () => {
  lazyRoute.show();
  return import('@/vuePage/ai/TaskList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const CreateAiTask = () => {
  lazyRoute.show();
  return import('@/vuePage/ai/CreateAiTask.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const AiTemplate = () => {
  lazyRoute.show();
  return import('@/vuePage/ai/AiTemplate.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const RechargeList = () => {
  lazyRoute.show();
  return import('@/vuePage/ai/RechargeList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};

export default [
  {
    path: 'TaskList',
    name: 'TaskList',
    meta: {
      menuId: customMenuMap.TaskList
    },
    component: TaskList
  },
  {
    path: 'CreateAiTask',
    name: 'CreateAiTask',
    meta: {
      menuId: customMenuMap.CreateAiTask
    },
    component: CreateAiTask
  },
  {
    path: 'AiTemplate',
    name: 'AiTemplate',
    meta: {
      menuId: customMenuMap.AiTemplate
    },
    component: AiTemplate
  },
  {
    path: 'RechargeList',
    name: 'RechargeList',
    meta: {
      menuId: customMenuMap.RechargeList
    },
    component: RechargeList
  }
];
