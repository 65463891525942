var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "clearfix head_container",
      style: { backgroundColor: _vm.colorOne },
    },
    [
      _c("div", { staticClass: "left" }, [
        _c("div", { staticClass: "img" }, [
          _c("img", { attrs: { src: _vm.logo, alt: "" } }),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "manage_name lh_60" }, [
          _vm._v(_vm._s(_vm.systemName)),
        ]),
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "right" }, [
        _c("li", { staticClass: "lh_60", on: { click: _vm.login_out } }, [
          _c("i", { staticClass: "iconfont icon-power" }),
        ]),
        _vm._v(" "),
        _c(
          "li",
          {
            staticClass: "message verticalContainer",
            on: { mouseenter: _vm.showMessage, mouseleave: _vm.hideMessage },
          },
          [
            _c(
              "el-badge",
              { attrs: { "is-dot": "", hidden: !_vm.hasNotRead } },
              [_c("i", { staticClass: "iconfont icon-xiaoxi" })]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.viewCtr.message,
                    expression: "viewCtr.message",
                  },
                ],
                staticClass: "messageWrap scroll_bar",
              },
              [
                _vm._l(_vm.messages, function (message, index) {
                  return _c("div", { staticClass: "messageItem" }, [
                    _c("div", { staticClass: "clearfix" }, [
                      _c("div", { staticClass: "message_left" }, [
                        _vm._v("订单号"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "message_right cursorPointer",
                          class: {
                            color_r: !message.read,
                            color_blue: message.read,
                          },
                          on: {
                            click: function ($event) {
                              _vm.checkMessage(index)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(message.orderNo) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "clearfix" }, [
                      _c("div", { staticClass: "message_left" }, [
                        _vm._v("订单类型"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "message_right" }, [
                        _vm._v(_vm._s(message.orderTypeDesc)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "clearfix" }, [
                      _c("div", { staticClass: "message_left" }, [
                        _vm._v("合计金额"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "message_right" }, [
                        _vm._v(_vm._s(message.money) + "（元）"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "clearfix" }, [
                      _c("div", { staticClass: "message_left" }, [
                        _vm._v("时间"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "message_right" }, [
                        _vm._v(_vm._s(message.crtTime)),
                      ]),
                    ]),
                  ])
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.messages.length == 0,
                        expression: "messages.length == 0",
                      },
                    ],
                    staticClass: "emptyTip",
                  },
                  [_vm._v("暂无消息")]
                ),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "lh_60", on: { click: _vm.home } }, [
          _c("i", { staticClass: "iconfont icon-home" }),
        ]),
      ]),
      _vm._v(" "),
      _c("audio", { attrs: { id: "messageAudio", controls: "controls" } }, [
        _c("source", { attrs: { src: _vm.imgBase + _vm.audioOne } }),
        _vm._v(" "),
        _c("source", { attrs: { src: _vm.imgBase + _vm.audioTwo } }),
        _vm._v(" "),
        _c("source", { attrs: { src: _vm.imgBase + _vm.audioThree } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }