<template>
  <div class="user_item_com" :class="{ active: active }" @click="toggle_chat_person">
    <div class="name_wrap">
      <div class="name">
        <span>{{ name }}</span>
        <span class="new_icon" v-show="notRead"></span>
      </div>
    </div>
    <div class="msg one_line">{{ content }}</div>
  </div>
</template>
<script>
export default {
  mixins: [],
  props: {
    name: {
      type: String
    },
    content: {
      type: String
    },
    userId: {
      type: String
    },
    active: {
      type: Boolean,
      default: false
    },
    notRead: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    toggle_chat_person() {
      if (!this.active) {
        this.$emit('select', this.userId);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.user_item_com {
  padding: 10px 12px;
  cursor: pointer;
  position: relative;
  .name_wrap {
    padding-bottom: 1px;
    .name {
      font-size: 16px;
      line-height: 18px;
      height: 18px;
      display: inline-block;
      max-width: 90%;
      position: relative;
      padding-right: 15px;
      overflow: hidden;
    }
    .new_icon {
      position: absolute;
      right: 0;
      top: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: red;
    }
  }
  .msg {
    max-width: 95%;
    font-size: 12px;
    height: 14px;
    line-height: 14px;
    color: #999;
  }
  &.active {
    background-color: #c7c6c6;
  }
  &:hover {
    background-color: #dedcda;
  }
}
</style>
