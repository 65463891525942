import lazyRoute from '@/router/lazyRoute';
import { customMenuMap } from '../../js/menu';
const CustomIndex = () => {
  lazyRoute.show();
  return import('@/vuePage/pagemanage/CustomIndex.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const CustomList = () => {
  lazyRoute.show();
  return import('@/vuePage/pagemanage/CustomList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const CustomResources = () => {
  lazyRoute.show();
  return import('@/vuePage/pagemanage/CustomResources.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const StoreResource = () => {
  lazyRoute.show();
  return import('@/vuePage/pagemanage/StoreResource.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};

export default [
  {
    path: 'customindex',
    name: 'customindex',
    meta: {
      menuId: customMenuMap.indexDecoration
    },
    component: CustomIndex
  },
  {
    path: 'customlist',
    name: 'customlist',
    meta: {
      menuId: customMenuMap.indexDecoration
    },
    component: CustomList
  },
  {
    path: 'customres',
    name: 'customres',
    meta: {
      menuId: customMenuMap.materialManage
    },
    component: CustomResources
  },
  {
    path: 'storeResource',
    name: 'storeResource',
    meta: {
      menuId: customMenuMap.storeResource
    },
    component: StoreResource
  }
];
