var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "user_item_com",
      class: { active: _vm.active },
      on: { click: _vm.toggle_chat_person },
    },
    [
      _c("div", { staticClass: "name_wrap" }, [
        _c("div", { staticClass: "name" }, [
          _c("span", [_vm._v(_vm._s(_vm.name))]),
          _vm._v(" "),
          _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.notRead,
                expression: "notRead",
              },
            ],
            staticClass: "new_icon",
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "msg one_line" }, [_vm._v(_vm._s(_vm.content))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }