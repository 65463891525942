<template>
  <div class="content_img">
    <img :src="url + zipRuler" alt="" @click="check_img" />
  </div>
</template>
<script>
export default {
  mixins: [],
  props: {
    url: {
      type: String
    }
  },
  components: {},
  data() {
    return {
      zipRuler: '?x-oss-process=image/resize,p_20'
    };
  },
  created() {},
  methods: {
    check_img() {
      window.open(this.url);
    }
  }
};
</script>
<style lang="scss" scoped>
.content_img {
  padding-top: 10px;
  img {
    max-width: 140px;
    min-width: 20px;
    max-height: 140px;
    min-height: 20px;
    cursor: pointer;
  }
}
</style>
