<template>
  <transition name="msgbox-fade">
    <div v-show="visible" class="message_wrap" @click.self="handle_action('cancel')">
      <div class="message_content">
        <div class="message_title">
          <span class="title">{{ title }}</span>
          <img src="./confirm_delete.png" alt="" class="delete" @click="handle_action('cancel')" />
        </div>
        <div class="message">{{ message }}</div>
        <div class="bottom_btn">
          <div class="btn sure_btn" @click="handle_action('confirm')">确定</div>
          <div class="btn cancel_btn" @click="handle_action('cancel')">取消</div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  mixins: [],
  components: {},
  data() {
    return {
      visible: false,
      message: '',
      title: ''
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('hashchange', this.close);
    });
  },

  beforeDestroy() {
    window.removeEventListener('hashchange', this.close);
  },
  methods: {
    handle_action(action) {
      this.close();
      this.visible = false;
      this.callback(action);
    },
    doClose() {
      if (!this.visible) return;
      this.visible = false;
    },
    close() {
      this.doClose();
    }
  }
};
</script>
<style lang="scss" scoped>
.message_wrap {
  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  background-color: rgba(3, 3, 3, 0.7);
  &::after {
    content: '';
    display: inline-block;
    height: 100%;
    width: 0;
    vertical-align: middle;
  }
}
.message_content {
  width: 520px;
  padding: 0 6px 23px 6px;
  box-sizing: border-box;
  display: inline-block;
  background-color: #fff;
  vertical-align: middle;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border: solid 1px #d5d5d5;
  text-align: left;
  .message_title {
    height: 49px;
    box-sizing: border-box;
    padding-left: 11px;
    border-bottom: 4px solid #ececec;
    position: relative;
    .title {
      line-height: 45px;
      font-size: 18px;
      color: #5c5c5c;
    }
    .delete {
      position: absolute;
      right: 11px;
      top: 12px;
      width: 19px;
      height: 21px;
      cursor: pointer;
    }
  }
  .message {
    padding: 35px 0 44px 0;
    text-align: center;
    font-size: 18px;
    color: #5c5c5c;
  }
  .bottom_btn {
    text-align: center;
    .btn {
      padding: 0 24px;
      display: inline-block;
      height: 47px;
      border-radius: 6px;
      font-size: 22px;
      line-height: 47px;
      cursor: pointer;
      &.sure_btn {
        margin-right: 32px;
        color: #fff;
        background-color: #63b8f8;
      }
      &.cancel_btn {
        color: #5c5c5c;
        background-color: #e7e7e7;
      }
    }
  }
}
.msgbox-fade-enter-active {
  animation: msgbox-fade-in 0.3s;
}

.msgbox-fade-leave-active {
  animation: msgbox-fade-out 0.3s;
}

@keyframes msgbox-fade-in {
  0% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes msgbox-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }
}
</style>
