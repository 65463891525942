var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.msg.position == _vm.positionMap.left &&
      _vm.msg.type == _vm.msgTypeMap.text
        ? _c("msg-left-text", {
            attrs: { content: _vm.msg.content, avatar: _vm.msg.headImgUrl },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.msg.position == _vm.positionMap.left &&
      _vm.msg.type == _vm.msgTypeMap.img
        ? _c("msg-left-img", {
            attrs: { url: _vm.msg.content.url, avatar: _vm.msg.headImgUrl },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.msg.position == _vm.positionMap.right &&
      _vm.msg.type == _vm.msgTypeMap.img
        ? _c("msg-right-img", {
            attrs: { url: _vm.msg.content.url, avatar: _vm.msg.headImgUrl },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.msg.position == _vm.positionMap.right &&
      _vm.msg.type == _vm.msgTypeMap.text
        ? _c("msg-right-text", {
            attrs: { content: _vm.msg.content, avatar: _vm.msg.headImgUrl },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }