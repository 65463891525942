<template>
  <div class="frame_right_com">
    <div class="top_title" :style="{ backgroundColor: mainColor }">
      <span class="title">用户信息</span>
      <div class="close_wrap">
        <img src="../../assets/image/icon_14.png" class="close_icon" alt="" @click="hide_frame" />
      </div>
    </div>
    <div class="info_box">
      <div class="info_line">昵称：{{ userInfo.nickname }}</div>
      <div class="info_line">手机：{{ userInfo.phone }}</div>
      <div class="info_line">余额：￥{{ userInfo.account }}</div>
      <div class="info_line">会员等级：{{ userInfo.vipLevelName }}</div>
      <div class="info_line">累计消费：￥{{ userInfo.consume }}</div>
      <div class="user_detail"><span @click="go_detail">用户详情</span></div>
    </div>
    <div class="fast_reply_title" :style="{ backgroundColor: mainColor }">快捷回复</div>
    <div class="fast_reply_list scroll_bar">
      <div class="fast_reply_item" v-for="msg in msgTemplate">
        <span class="msg" @click="get_fast_msg(msg.content)">{{ msg.simpleWord }}</span>
        <div class="send" @click="send_msg(msg.content)">发送</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mixins: [],
  props: {
    msgTemplate: {
      type: Array,
      default() {
        return [];
      }
    },
    userInfo: {
      type: Object
    },
    mainColor: {
      type: String,
      default: '#e5e5e5'
    }
  },
  components: {},
  data() {
    return {
      msgList: []
    };
  },
  created() {},
  methods: {
    hide_frame() {
      this.$emit('hideFrame');
    },
    get_fast_msg(msg) {
      this.$emit('getFastMsg', msg);
    },
    send_msg(msg) {
      this.$emit('send', msg);
    },
    go_detail() {
      this.$router.push({ path: '/vipDetail', query: { id: this.userInfo.userId } });
    }
  }
};
</script>
<style lang="scss" scoped>
.top_title {
  height: 40px;
  position: relative;
  padding-left: 15px;
  .title {
    line-height: 40px;
  }
  .close_wrap {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .close_icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
.info_box {
  padding: 20px 10px;
  .info_line {
    padding: 5px 0;
  }
  .user_detail {
    margin-top: 20px;
    text-align: center;
    span {
      cursor: pointer;
      color: #63b8f8;
    }
  }
}
.fast_reply_title {
  height: 40px;
  background-color: #e5e5e5;
  padding-left: 15px;
  line-height: 40px;
}
.fast_reply_list {
  padding: 20px 10px;
  height: 300px;
  box-sizing: border-box;
  overflow-y: auto;
  .fast_reply_item {
    font-size: 12px;
    color: #666;
    height: 30px;
    border-bottom: 1px solid #cf9236;
    display: flex;
    align-items: center;
    padding-right: 30px;
    position: relative;
    overflow: hidden;
    .msg {
      cursor: pointer;
    }
    .send {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #63b8f8;
    }
  }
}
</style>
