<template>
  <div class="avatar" :style="{ backgroundColor: mainColor, borderColor: mainColor }" v-if="true">
    <img :src="avatar" alt="" />
  </div>
</template>
<script>
export default {
  mixins: [],
  props: {
    mainColor: {
      type: String,
      required: true
    },
    avatar: {
      type: String
    }
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.avatar {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  img {
    max-width: 38px;
    max-height: 38px;
  }
}
</style>
