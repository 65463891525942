import PageLost from '@/components/PageLost.vue';
import { Loading } from 'element-ui';
let loading = null;
export default {
  show: function () {
    loading = Loading.service({
      lock: true,
      text: '加载页面',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
  },
  close: function () {
    if (loading) {
      loading.close();
      loading = null;
    }
  },
  success: function (component) {
    this.close();
    return component;
  },
  error: function () {
    this.close();
    return PageLost;
  }
};
