<template>
  <div class="msg_item_com">
    <div class="avatar_wrap">
      <avatar :mainColor="left_color" :avatar="avatar"></avatar>
    </div>
    <content-img v-if="url" :url="url"> </content-img>
  </div>
</template>
<script>
import Avatar from './Avatar.vue';
import ContentImg from './ContentImg.vue';
export default {
  mixins: [],
  props: {
    url: {
      type: String,
      default: ''
    },
    avatar: {
      type: String
    }
  },
  components: {
    avatar: Avatar,
    'content-img': ContentImg
  },
  data() {
    return {
      left_color: '#fff',
      fontColor: '#333'
    };
  },
  created() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.msg_item_com {
  padding: 10px 0;
  display: flex;
  .avatar_wrap {
    margin-right: 10px;
  }
}
</style>
