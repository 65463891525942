<template>
  <div :class="classObj" class="hm_btn" @click="btn_click">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    plan: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'middle'
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    classObj() {
      let obj = {};
      // 大小相光
      let sizeName = 'hm_';
      sizeName += this.size;
      if (this.plan) {
        sizeName += '_plan';
      }
      obj[sizeName] = true;
      // 风格
      let styleName = 'hm_';
      styleName += this.type;
      if (this.plan) {
        styleName += '_plan';
      }
      obj[styleName] = true;
      return obj;
    }
  },
  methods: {
    btn_click() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
.hm_btn {
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  .iconfont {
    margin-right: 7px;
  }
}
/*迷你*/
.hm_mini {
  font-size: 12px;
  padding: 0 8px;
  line-height: 20px;
}
.hm_mini_plan {
  font-size: 12px;
  padding: 0 7px;
  border: 1px solid #e5e5e5;
  line-height: 18px;
}
/*小*/
.hm_small {
  font-size: 15px;
  padding: 0 15px;
  line-height: 28px;
}
.hm_small_plan {
  font-size: 15px;
  padding: 0 14px;
  border: 1px solid #e5e5e5;
  line-height: 26px;
}
/*中等*/
.hm_middle {
  line-height: 30px;
  padding: 0 10px;
  font-size: 15px;
}
.hm_middle_plan {
  line-height: 28px;
  border: 1px solid #e5e5e5;
  padding: 0 9px;
  font-size: 15px;
}
/*大*/
.hm_big {
  width: 200px;
  line-height: 58px;
  text-align: center;
  font-size: 18px;
}
.hm_big_plan {
  width: 200px;
  border: 1px solid #e5e5e5;
  text-align: center;
  font-size: 18px;
  line-height: 56px;
}
/*默认*/
.hm_default {
  background-color: #e5e5e5;
  color: #666;
}
.hm_default_plan {
  border-color: #e5e5e5;
  color: #666;
}
/*汇美黄*/
.hm_hymn {
  background-color: #ffe000;
  color: #666;
}
.hm_hymn_plan {
  border-color: #ffe000;
  color: #666;
}
/*主色*/
.hm_primary {
  background-color: #63b8f8;
  color: #fff;
}
.hm_primary_plan {
  border-color: #63b8f8;
  color: #666;
}
</style>
