var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "menu-item",
      class: { unvalid: !_vm.menuItem.enabled },
      on: { click: _vm.menu_item_click },
    },
    [
      _c(
        "div",
        {
          staticClass: "nameWrap",
          style: {
            backgroundColor:
              _vm.menuItem.id == _vm.activeId ? _vm.colorOne : "",
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.menuItem.name) + "\n  ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }