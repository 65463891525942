<template>
  <div>
    <div class="subTitle" @click="sub_title_click">
      <div class="name">{{ menuItem.name }}</div>
      <div class="arrowWrap">
        <img
          src="../assets/image/down_arrow.png"
          alt=""
          class="arrow"
          :class="{ collapse: collapse }"
        />
      </div>
    </div>
    <collapse>
      <div class="menu-items" v-show="collapse">
        <div v-for="subItem in menuItem.subMenu" :key="subItem.id">
          <lf-sub-menu
            v-if="subItem.subMenu.length > 0"
            :menuItem="subItem"
            :openIds="openIds"
            :colorOne="colorOne"
          ></lf-sub-menu>
          <lf-menu-item
            v-else
            :menuItem="subItem"
            v-on="$listeners"
            :activeId="activeId"
            :colorOne="colorOne"
          ></lf-menu-item>
        </div>
      </div>
    </collapse>
  </div>
</template>
<script>
import collapse from '../js/collapse';
import LfMenuItem from './LfMenuItem.vue';
export default {
  components: {
    collapse: collapse,
    'lf-menu-item': LfMenuItem
  },
  name: 'LfSubMenu',
  props: ['menuItem', 'openIds', 'activeId', 'colorOne'],
  data() {
    return {};
  },
  created() {},
  computed: {
    collapse() {
      let res = false;
      try {
        this.openIds.findIndex((id) => {
          if (id == this.menuItem.id) {
            res = true;
            return true;
          }
        });
      } catch (err) {}
      return res;
    }
  },
  methods: {
    sub_title_click() {
      if (this.collapse) {
        this.$emit('closeSub', this.menuItem.id);
      } else {
        this.$emit('openSub', this.menuItem.id);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.subTitle {
  height: 44px;
  cursor: pointer;
  position: relative;
  .name {
    padding: 0 24px 0 2px;
    line-height: 44px;
    font-size: 18px;
    color: #333;
    overflow: hidden;
    user-select: none;
  }
  .arrowWrap {
    position: absolute;
    right: 2px;
    top: 0;
    width: 18px;
    height: 100%;
    display: flex;
    align-items: center;
    .arrow {
      width: 18px;
      height: 10px;
      transition: transform 0.2s;
      &.collapse {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
