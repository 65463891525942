<template>
  <div class="frame_left_com">
    <div class="top_title" :style="{ backgroundColor: mainColor }">--对话列表--</div>
    <div class="user_list scroll_bar">
      <user-item
        v-for="(user, index) in data"
        :name="user.name"
        :notRead="user.notRead"
        :content="user.content"
        :active="user.active"
        :userId="user.userId"
        :key="user.userId"
        @select="toggle_chat_person"
      ></user-item>
    </div>
  </div>
</template>
<script>
import UserItem from './UserItem.vue';
export default {
  mixins: [],
  props: {
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    mainColor: {
      type: String,
      default: '#e5e5e5'
    }
  },
  components: {
    'user-item': UserItem
  },
  data() {
    return {
      test: [1, 2, 3],
      loading: false,
      total: 30
    };
  },
  created() {},
  computed: {
    scrollDisabled() {
      return this.loading || this.noMore;
    },
    noMore() {
      return this.test.length >= this.total;
    }
  },
  methods: {
    toggle_chat_person(userId) {
      this.$emit('toggleChatPerson', userId);
    }
  }
};
</script>
<style lang="scss" scoped>
.top_title {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user_list {
  height: 560px;
  overflow: auto;
}
</style>
