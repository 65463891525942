import axios from 'axios';
import { isTecent, Bucket, Region, imgBase } from '../config/common';
import $http from './httpRequest';
const COS = require('cos-js-sdk-v5');

let upload = null;
if (isTecent) {
  let tmpSecretId = '';
  let tmpSecretKey = '';
  let sessionToken = '';
  const cos = new COS({
    getAuthorization: function (options, callback) {
      var authorization = COS.getAuthorization({
        SecretId: tmpSecretId, // 可传固定密钥或者临时密钥
        SecretKey: tmpSecretKey, // 可传固定密钥或者临时密钥
        Method: options.Method,
        Pathname: options.Pathname,
        Query: options.Query,
        Headers: options.Headers,
        Expires: 900
      });
      callback({
        Authorization: authorization,
        XCosSecurityToken: sessionToken
      });
    }
  });
  upload = function ({ file, type, progress, fileName = '' } = {}) {
    return new Promise((resolve, reject) => {
      fileName = fileName ? fileName : file.name;
      $http({
        url: `/cos/sts/getCredential?type=${type}&fileName=${fileName}`,
        method: 'post'
      })
        .then((res) => {
          let cosData = JSON.parse(res.data.data);
          let credentials = cosData.credentials;
          tmpSecretId = credentials.tmpSecretId;
          tmpSecretKey = credentials.tmpSecretKey;
          sessionToken = credentials.sessionToken;
          cos.putObject(
            {
              Bucket: Bucket,
              Region: Region,
              Key: cosData.dir,
              Body: file,
              onProgress: function (progressData) {
                progress(progressData);
              }
            },
            function (err, res) {
              if (err) {
                reject(err);
              } else {
                resolve(cosData.dir);
              }
            }
          );
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
} else {
  upload = function ({ file, type, progress, fileName = '' } = {}) {
    return new Promise((resolve, reject) => {
      fileName = fileName ? fileName : file.name;
      $http({
        url: '/oss/getOssInfo',
        params: {
          type: type,
          filename: fileName
        }
      })
        .then((res) => {
          let result = res.data.data;
          let formData = new FormData();
          formData.append('Filename', result.dir);
          formData.append('key', result.dir);
          formData.append('policy', result.policy);
          formData.append('OSSAccessKeyId', result.accessid);
          formData.append('signature', result.signature);
          formData.append('success_action_status', 200);
          formData.append('file', file);
          axios({
            baseURL: imgBase,
            url: '',
            method: 'post',
            header: {
              'content-type': 'multipart/form-data'
            },
            data: formData,
            onUploadProgress: (progressObj) => {
              progress(progressObj);
            }
          })
            .then((res) => {
              if (res.status == 200) {
                resolve(result.dir);
              } else {
                reject({});
              }
            })
            .catch((err) => {
              reject({});
            });
        })
        .catch((err) => {
          reject({});
        });
    });
  };
}

export default upload;
