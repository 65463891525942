<template>
  <div class="clearfix head_container" :style="{ backgroundColor: colorOne }">
    <div class="left">
      <div class="img"><img :src="logo" alt="" /></div>
      <span class="manage_name lh_60">{{ systemName }}</span>
    </div>
    <ul class="right">
      <li @click="login_out" class="lh_60"><i class="iconfont icon-power"></i></li>
      <li class="message verticalContainer" @mouseenter="showMessage" @mouseleave="hideMessage">
        <el-badge is-dot :hidden="!hasNotRead"><i class="iconfont icon-xiaoxi"></i></el-badge>
        <div class="messageWrap scroll_bar" v-show="viewCtr.message">
          <div class="messageItem" v-for="(message, index) in messages">
            <div class="clearfix">
              <div class="message_left">订单号</div>
              <div
                class="message_right cursorPointer"
                :class="{ color_r: !message.read, color_blue: message.read }"
                @click="checkMessage(index)"
              >
                {{ message.orderNo }}
              </div>
            </div>
            <div class="clearfix">
              <div class="message_left">订单类型</div>
              <div class="message_right">{{ message.orderTypeDesc }}</div>
            </div>
            <div class="clearfix">
              <div class="message_left">合计金额</div>
              <div class="message_right">{{ message.money }}（元）</div>
            </div>
            <div class="clearfix">
              <div class="message_left">时间</div>
              <div class="message_right">{{ message.crtTime }}</div>
            </div>
          </div>
          <div class="emptyTip" v-show="messages.length == 0">暂无消息</div>
        </div>
      </li>
      <li @click="home" class="lh_60"><i class="iconfont icon-home"></i></li>
      <!-- <li @click="toRepair" class="lh_60" style="cursor: pointer">老照片修复</li> -->
    </ul>
    <audio id="messageAudio" controls="controls">
      <source :src="imgBase + audioOne" />
      <source :src="imgBase + audioTwo" />
      <source :src="imgBase + audioThree" />
    </audio>
  </div>
</template>

<script>
import { getToken, setCookie } from '@/utils/cookie';
import notify from '../js/notify';
import appMixin from '../mixins/appMixin';
import storeMixin from '../mixins/storeMixin';
import { Badge } from 'element-ui';
import { lsNewMessage } from '../config/lsKey';
import { order_type_map } from '../js/order';
export default {
  props: ['systemName', 'colorOne', 'logo'],
  components: {
    'el-badge': Badge
  },
  mixins: [appMixin, storeMixin],
  data() {
    return {
      messages: [],
      viewCtr: {
        message: false
      },
      storeIds: [],
      audioOne: '/music/music.mp3',
      audioTwo: '/music/music.wav',
      audioThree: '/music/music.ogg',
      apiNewMessage: 'common/orderPush' //消息推送
    };
  },
  created() {
    this.initMessages();
    this.initData();
    window.addEventListener('storage', this.storage_change, false);
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.storage_change, false);
  },
  computed: {
    hasNotRead() {
      let isNot = false;
      let messages = this.messages;
      messages.findIndex((message) => {
        if (!message.read) {
          isNot = true;
          return true;
        }
      });
      return isNot;
    }
  },
  methods: {
    storage_change: function (e) {
      if (e.key == lsNewMessage) {
        this.initMessages();
      }
    },
    // 退出登录
    login_out() {
      setCookie('token', '');
      window.location.href = './login.html';
    },
    // 前往首页
    home() {
      this.$router.push('/Statistics');
    },
    toRepair() {
      this.$router.push('/repair');
    },
    async initData() {
      try {
        let stores = await this.get_manage_store();
        let storeIds = [];
        for (let store of stores) {
          storeIds.push(store.storeId);
        }
        this.storeIds = storeIds;
        this.updateMessages();
        setInterval(() => {
          this.updateMessages();
        }, 10000);
      } catch (err) {}
    },
    showMessage() {
      this.viewCtr.message = true;
    },
    hideMessage() {
      this.viewCtr.message = false;
    },
    initMessages() {
      let currentMessages = [];
      if (localStorage.getItem(lsNewMessage)) {
        let messagesObj = JSON.parse(localStorage.getItem(lsNewMessage)) || {};
        if (messagesObj.token == getToken()) {
          currentMessages = messagesObj.messages || [];
        } else {
          localStorage.removeItem(lsNewMessage);
        }
      }
      this.messages = currentMessages;
    },
    updateMessages() {
      this.requestNewMessage({ storeIds: this.storeIds })
        .then((messages) => {
          if (messages.length > 0) {
            let newMessages = [];
            let orderTypeMap = order_type_map;
            for (let message of messages) {
              let orderTypeDesc = '';
              switch (Number(message.orderType)) {
                case orderTypeMap.offlineYp:
                  orderTypeDesc = '服务订单(到店)';
                  break;
                case orderTypeMap.groupYp:
                  orderTypeDesc = '服务订单(拼团)';
                  break;
                case orderTypeMap.yp:
                  orderTypeDesc = '服务订单';
                  break;
                case orderTypeMap.offlineGeneral:
                  orderTypeDesc = '电商订单(到店)';
                  break;
                case orderTypeMap.groupGeneral:
                  orderTypeDesc = '电商订单(拼团)';
                  break;
                case orderTypeMap.product:
                  orderTypeDesc = '电商订单';
                  break;
              }
              newMessages.push({
                orderNo: message.orderNo,
                money: message.orderTotalPrice,
                orderType: message.orderType,
                crtTime: message.crtTime,
                read: false,
                orderTypeDesc: orderTypeDesc
              });
            }
            let currentMessages = [];
            if (localStorage.getItem(lsNewMessage)) {
              let messagesObj = JSON.parse(localStorage.getItem(lsNewMessage)) || {};
              currentMessages = messagesObj.messages || [];
            }
            newMessages = [...newMessages, ...currentMessages];
            this.messages = newMessages;
            localStorage.setItem(
              lsNewMessage,
              JSON.stringify({
                messages: newMessages,
                token: getToken()
              })
            );
            notify.show({
              title: '订单消息',
              body: '您有新的订单，请及时处理'
            });
            try {
              let audio = document.getElementById('messageAudio');
              audio
                .play()
                .then((res) => {
                  console.log('成功');
                })
                .catch((err) => {
                  this.$notify({
                    type: 'success',
                    duration: 0,
                    title: '订单消息',
                    message: '您有新的订单，请及时处理'
                  });
                });
            } catch (err) {}
          }
        })
        .catch((err) => {});
    },
    checkMessage(clickIndex) {
      let message = this.messages[clickIndex];
      if (!message.read) {
        this.messages[clickIndex].read = true;
        localStorage.setItem(
          lsNewMessage,
          JSON.stringify({
            messages: this.messages,
            token: getToken()
          })
        );
      }
      let orderTypeMap = order_type_map;
      switch (Number(message.orderType)) {
        case orderTypeMap.groupYp:
        case orderTypeMap.yp:
          this.$router.push({ path: '/ypOrder', query: { orderNo: message.orderNo } });
          break;
        case orderTypeMap.groupGeneral:
        case orderTypeMap.product:
          this.$router.push({ path: '/photoOrder', query: { orderNo: message.orderNo } });
          break;
        case orderTypeMap.offlineYp:
          this.$router.push({ path: '/offlineYpOrder', query: { orderNo: message.orderNo } });
          break;
        case orderTypeMap.offlineGeneral:
          this.$router.push({ path: '/offlineGeneralOrder', query: { orderNo: message.orderNo } });
          break;
      }
    },
    /***********请求***********/
    requestNewMessage({ storeIds } = {}) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: this.apiNewMessage,
          method: 'get',
          params: {
            storeIdList: storeIds.join(',')
          }
        })
          .then((res) => {
            if (res.data.success) {
              let messages = res.data.data || [];
              resolve(messages);
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((err) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    requestYxtMessage() {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/retouching/message/getNewMessage',
          method: 'get'
        })
          .then((res) => {
            if (res.data.success) {
              let messages = res.data.data || [];
              resolve(messages);
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((err) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    }
    /***********请求结束***********/
  }
};
</script>

<style lang="scss" scoped>
#messageAudio {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  opacity: 0;
  width: 200px;
  height: 50px;
}
#messageAudio2 {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  opacity: 0;
  width: 200px;
  height: 50px;
}
.lh_60 {
  line-height: 60px;
}
.head_container {
  padding: 0 58px;
  width: 100%;
  box-sizing: border-box;
}
.left {
  float: left;
  .img {
    margin-right: 14px;
    float: left;
    width: 35px;
    height: 60px;
    line-height: 60px;
    vertical-align: middle;
    display: table-cell;
    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }
  .manage_name {
    float: left;
  }
}
.right {
  float: right;
  li {
    float: right;
    width: 92px;
    height: 60px;
    text-align: center;
  }
  .person-info {
    position: relative;
    height: 100%;
    .tool-box {
      position: absolute;
      left: 0;
      bottom: -50px;
      .tool {
      }
    }
  }
  .message {
    position: relative;
    .messageWrap {
      position: absolute;
      left: -100px;
      bottom: -400px;
      z-index: 100;
      width: 302px;
      height: 402px;
      box-sizing: border-box;
      overflow-y: auto;
      background-color: #fff;
      border: 1px solid #dedede;
      .messageItem {
        border-bottom: 1px solid #dedede;
        .message_left {
          width: 100px;
          text-align: center;
          line-height: 40px;
          float: left;
        }
        .message_right {
          width: 170px;
          text-align: center;
          line-height: 40px;
          float: right;
        }
      }
      .messageItem2 {
        border-bottom: 1px solid #dedede;
        padding: 10px 2px;
        .orderNo {
          line-height: 30px;
          cursor: pointer;
        }
        .content {
          text-align: left;
        }
      }
      .emptyTip {
        height: 80px;
        text-align: center;
        line-height: 80px;
        color: #999;
      }
    }
  }
  .iconfont {
    &:before {
      font-size: 25px;
      cursor: pointer;
    }
    &:hover {
      color: #f00;
    }
  }
}
</style>
