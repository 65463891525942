import lazyRoute from '@/router/lazyRoute';
import { customMenuMap } from '../../js/menu';
const GeneralSetting = () => {
  lazyRoute.show();
  return import('@/vuePage/setting/GeneralSetting.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const PaySet = () => {
  lazyRoute.show();
  return import('@/vuePage/setting/PaySet.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const PostageSetting = () => {
  lazyRoute.show();
  return import('@/vuePage/setting/PostageSetting.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const BasicSetting = () => {
  lazyRoute.show();
  return import('@/vuePage/setting/BasicSetting.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const StoreList = () => {
  lazyRoute.show();
  return import('@/vuePage/setting/StoreList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const AddStore = () => {
  lazyRoute.show();
  return import('@/vuePage/setting/AddStore.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const CheckRole = () => {
  lazyRoute.show();
  return import('@/vuePage/role/CheckRole.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const AddRole = () => {
  lazyRoute.show();
  return import('@/vuePage/role/AddRole.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const RoleList = () => {
  lazyRoute.show();
  return import('@/vuePage/role/RoleList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const StaffList = () => {
  lazyRoute.show();
  return import('@/vuePage/role/StaffList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const AddStaff = () => {
  lazyRoute.show();
  return import('@/vuePage/role/AddStaff.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const EditStaff = () => {
  lazyRoute.show();
  return import('@/vuePage/role/EditStaff.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const LoginHistory = () => {
  lazyRoute.show();
  return import('@/vuePage/role/LoginHistory.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const OperateLog = () => {
  lazyRoute.show();
  return import('@/vuePage/role/OperateLog.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const CustomSetting = () => {
  lazyRoute.show();
  return import('@/vuePage/setting/CustomSetting.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};

export default [
  {
    path: 'customSetting',
    name: 'customSetting',
    meta: {
      menuId: customMenuMap.businessStyleSetting
    },
    component: CustomSetting
  },
  {
    path: 'operateLog',
    name: 'operateLog',
    meta: {
      menuId: customMenuMap.operationLog
    },
    component: OperateLog
  },
  {
    path: 'loginHistory',
    name: 'loginHistory',
    meta: {
      menuId: customMenuMap.loginLog
    },
    component: LoginHistory
  },
  {
    path: 'editStaff',
    name: 'editStaff',
    meta: {
      menuId: customMenuMap.staffManage
    },
    component: EditStaff
  },
  {
    path: 'addStaff',
    name: 'addStaff',
    meta: {
      menuId: customMenuMap.staffManage
    },
    component: AddStaff
  },
  {
    path: 'staffList',
    name: 'staffList',
    meta: {
      menuId: customMenuMap.staffManage
    },
    component: StaffList
  },
  {
    path: 'generalSetting',
    name: 'generalSetting',
    meta: {
      menuId: customMenuMap.xcxAuthorization
    },
    component: GeneralSetting
  },
  {
    path: 'paySet',
    name: 'paySet',
    meta: {
      menuId: customMenuMap.paySetting
    },
    component: PaySet
  },
  {
    path: 'postageSetting',
    name: 'postageSetting',
    meta: {
      menuId: customMenuMap.storeList
    },
    component: PostageSetting
  },
  {
    path: 'basicSetting',
    name: 'basicSetting',
    meta: {
      menuId: customMenuMap.accountEdit
    },
    component: BasicSetting
  },
  {
    path: 'storeList',
    name: 'storeList',
    meta: {
      menuId: customMenuMap.storeList
    },
    component: StoreList
  },
  {
    path: 'addStore',
    name: 'addStore',
    meta: {
      menuId: customMenuMap.storeList
    },
    component: AddStore
  },
  {
    path: 'checkRole',
    name: 'checkRole',
    meta: {
      menuId: customMenuMap.roleManage
    },
    component: CheckRole
  },
  {
    path: 'addRole',
    name: 'addRole',
    meta: {
      menuId: customMenuMap.roleManage
    },
    component: AddRole
  },
  {
    path: 'roleList',
    name: 'roleList',
    meta: {
      menuId: customMenuMap.roleManage
    },
    component: RoleList
  }
];
