var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "msgbox-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        staticClass: "message_wrap",
        on: {
          click: function ($event) {
            if ($event.target !== $event.currentTarget) {
              return null
            }
            _vm.handle_action("cancel")
          },
        },
      },
      [
        _c("div", { staticClass: "message_content" }, [
          _c("div", { staticClass: "message_title" }, [
            _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
            _vm._v(" "),
            _c("img", {
              staticClass: "delete",
              attrs: { src: require("./confirm_delete.png"), alt: "" },
              on: {
                click: function ($event) {
                  _vm.handle_action("cancel")
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "message" }, [_vm._v(_vm._s(_vm.message))]),
          _vm._v(" "),
          _c("div", { staticClass: "bottom_btn" }, [
            _c(
              "div",
              {
                staticClass: "btn sure_btn",
                on: {
                  click: function ($event) {
                    _vm.handle_action("confirm")
                  },
                },
              },
              [_vm._v("确定")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn cancel_btn",
                on: {
                  click: function ($event) {
                    _vm.handle_action("cancel")
                  },
                },
              },
              [_vm._v("取消")]
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }