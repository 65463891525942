var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "frame_right_com" }, [
    _c(
      "div",
      { staticClass: "top_title", style: { backgroundColor: _vm.mainColor } },
      [
        _c("span", { staticClass: "title" }, [_vm._v("用户信息")]),
        _vm._v(" "),
        _c("div", { staticClass: "close_wrap" }, [
          _c("img", {
            staticClass: "close_icon",
            attrs: { src: require("../../assets/image/icon_14.png"), alt: "" },
            on: { click: _vm.hide_frame },
          }),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "info_box" }, [
      _c("div", { staticClass: "info_line" }, [
        _vm._v("昵称：" + _vm._s(_vm.userInfo.nickname)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "info_line" }, [
        _vm._v("手机：" + _vm._s(_vm.userInfo.phone)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "info_line" }, [
        _vm._v("余额：￥" + _vm._s(_vm.userInfo.account)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "info_line" }, [
        _vm._v("会员等级：" + _vm._s(_vm.userInfo.vipLevelName)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "info_line" }, [
        _vm._v("累计消费：￥" + _vm._s(_vm.userInfo.consume)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "user_detail" }, [
        _c("span", { on: { click: _vm.go_detail } }, [_vm._v("用户详情")]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "fast_reply_title",
        style: { backgroundColor: _vm.mainColor },
      },
      [_vm._v("快捷回复")]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "fast_reply_list scroll_bar" },
      _vm._l(_vm.msgTemplate, function (msg) {
        return _c("div", { staticClass: "fast_reply_item" }, [
          _c(
            "span",
            {
              staticClass: "msg",
              on: {
                click: function ($event) {
                  _vm.get_fast_msg(msg.content)
                },
              },
            },
            [_vm._v(_vm._s(msg.simpleWord))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "send",
              on: {
                click: function ($event) {
                  _vm.send_msg(msg.content)
                },
              },
            },
            [_vm._v("发送")]
          ),
        ])
      })
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }