<template>
  <div class="menu-item" :class="{ unvalid: !menuItem.enabled }" @click="menu_item_click">
    <div class="nameWrap" :style="{ backgroundColor: menuItem.id == activeId ? colorOne : '' }">
      {{ menuItem.name }}
    </div>
  </div>
</template>
<script>
export default {
  props: ['menuItem', 'activeId', 'colorOne'],
  data() {
    return {};
  },
  created() {},
  methods: {
    menu_item_click() {
      this.$emit('menuItemClick', this.menuItem);
    }
  }
};
</script>
<style lang="scss" scoped>
.menu-item {
  height: 64px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #666;
  .nameWrap {
    width: 80px;
    height: 30px;
    border-radius: 4px;
    text-align: center;
    line-height: 30px;
    overflow: hidden;
    user-select: none;
  }
  &.unvalid {
    cursor: not-allowed;
    .nameWrap {
      color: #999;
    }
  }
}
</style>
