var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "subTitle", on: { click: _vm.sub_title_click } },
        [
          _c("div", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.menuItem.name)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "arrowWrap" }, [
            _c("img", {
              staticClass: "arrow",
              class: { collapse: _vm.collapse },
              attrs: {
                src: require("../assets/image/down_arrow.png"),
                alt: "",
              },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("collapse", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.collapse,
                expression: "collapse",
              },
            ],
            staticClass: "menu-items",
          },
          _vm._l(_vm.menuItem.subMenu, function (subItem) {
            return _c(
              "div",
              { key: subItem.id },
              [
                subItem.subMenu.length > 0
                  ? _c("lf-sub-menu", {
                      attrs: {
                        menuItem: subItem,
                        openIds: _vm.openIds,
                        colorOne: _vm.colorOne,
                      },
                    })
                  : _c(
                      "lf-menu-item",
                      _vm._g(
                        {
                          attrs: {
                            menuItem: subItem,
                            activeId: _vm.activeId,
                            colorOne: _vm.colorOne,
                          },
                        },
                        _vm.$listeners
                      )
                    ),
              ],
              1
            )
          })
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }