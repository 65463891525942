<template>
  <div class="msg_item_com">
    <content-img v-if="url" :url="url"> </content-img>
    <div class="avatar_wrap">
      <avatar :mainColor="right_color" :avatar="avatar"></avatar>
    </div>
  </div>
</template>
<script>
import Avatar from './Avatar.vue';
import ContentImg from './ContentImg.vue';
export default {
  mixins: [],
  props: {
    url: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    }
  },
  components: {
    avatar: Avatar,
    'content-img': ContentImg
  },
  data() {
    return {
      right_color: '#ff7136',
      fontColor: '#fff'
    };
  },
  created() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.msg_item_com {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  .avatar_wrap {
    margin-left: 10px;
  }
}
</style>
