export default {
  getCursorPosition(element) {
    let sel = window.getSelection();
    if (sel.containsNode(element, true) && sel.rangeCount > 0) {
      let range = sel.getRangeAt(0);
      let preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      return preCaretRange;
    } else {
      let range = document.createRange();
      range.selectNodeContents(element);
      range.collapse();
      return range;
    }
  },
  setCursorPosition(element, rg) {
    if (rg) {
      let range = document.createRange();
      range.setStart(rg.endContainer, rg.endOffset);
      range.setEnd(rg.endContainer, rg.endOffset);
      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      return range;
    } else {
      element.focus();
    }
  }
};
