var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.menu, function (menuItem) {
      return _c(
        "div",
        { key: menuItem.id },
        [
          menuItem.subMenu.length > 0
            ? _c("lf-sub-menu", {
                attrs: {
                  menuItem: menuItem,
                  openIds: _vm.openId,
                  activeId: _vm.activeId,
                  colorOne: _vm.colorOne,
                },
                on: {
                  openSub: _vm.openSub,
                  closeSub: _vm.closeSub,
                  menuItemClick: _vm.menuItemClick,
                },
              })
            : _c("lf-menu-item", {
                attrs: {
                  menuItem: menuItem,
                  activeId: _vm.activeId,
                  colorOne: _vm.colorOne,
                },
                on: { menuItemClick: _vm.menuItemClick },
              }),
        ],
        1
      )
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }