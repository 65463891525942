<template>
  <div class="msg_item_com">
    <content-text :mainColor="right_color" :fontColor="fontColor" :content="content">
    </content-text>
    <div class="avatar_wrap">
      <avatar :mainColor="right_color" :avatar="avatar"></avatar>
    </div>
  </div>
</template>
<script>
import Avatar from './Avatar.vue';
import ContentText from './ContentText.vue';
export default {
  mixins: [],
  props: {
    content: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    }
  },
  components: {
    avatar: Avatar,
    'content-text': ContentText
  },
  data() {
    return {
      right_color: '#98e165',
      fontColor: '#333'
    };
  },
  created() {},
  methods: {}
};
</script>
<style lang="scss" scoped>
.msg_item_com {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  .avatar_wrap {
    margin-left: 10px;
  }
}
</style>
