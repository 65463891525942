module.exports = {
  init() {
    if (window.Notification) {
      console.log(Notification.permission);
      Notification.requestPermission(function (status) {
        console.log(status);
      });
    }
  },
  show({ title = '', body = '' } = {}) {
    let n = null;
    if ('Notification' in window && Notification.permission === 'granted') {
      n = new Notification(title, {
        body: body
      });
      n.onclick = function () {
        window.focus();
      };
    } else if ('Notification' in window && Notification.permission !== 'denied') {
      Notification.requestPermission(function (status) {
        if (status === 'granted') {
          n = new Notification(title, {
            body: body
          });
          n.onclick = function () {
            window.focus();
          };
        }
      });
    }
  }
};
