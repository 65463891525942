var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "msg_item_com" },
    [
      _c(
        "div",
        { staticClass: "avatar_wrap" },
        [
          _c("avatar", {
            attrs: { mainColor: _vm.left_color, avatar: _vm.avatar },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("content-text", {
        attrs: {
          mainColor: _vm.left_color,
          fontColor: _vm.fontColor,
          content: _vm.content,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }