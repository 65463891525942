import lazyRoute from '@/router/lazyRoute';
import { customMenuMap } from '../../js/menu';
const VipList = () => {
  lazyRoute.show();
  return import('@/vuePage/vip/VipList.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const VipDetail = () => {
  lazyRoute.show();
  return import('@/vuePage/vip/VipDetail.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const VipPics = () => {
  lazyRoute.show();
  return import('@/vuePage/vip/VipPics.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const VipList2 = () => {
  lazyRoute.show();
  return import('@/vuePage/vip/VipList2.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const LevelManage = () => {
  lazyRoute.show();
  return import('@/vuePage/vip/LevelManage.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const RechargeRecord = () => {
  lazyRoute.show();
  return import('@/vuePage/vip/RechargeRecord.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};

export default [
  {
    path: 'vipList',
    name: 'vipList',
    meta: {
      menuId: customMenuMap.userList
    },
    component: VipList
  },
  {
    path: 'vipDetail',
    name: 'vipDetail',
    meta: {
      menuId: customMenuMap.userList
    },
    component: VipDetail
  },
  {
    path: 'vipPics',
    name: 'vipPics',
    meta: {
      menuId: customMenuMap.userList
    },
    component: VipPics
  },
  {
    path: 'vipList2',
    name: 'vipList2',
    meta: {
      menuId: customMenuMap.vipList
    },
    component: VipList2
  },
  {
    path: 'levelManage',
    name: 'levelManage',
    meta: {
      menuId: customMenuMap.vipLevelManage
    },
    component: LevelManage
  },
  {
    path: 'rechargeRecord',
    name: 'rechargeRecord',
    meta: {
      menuId: customMenuMap.rechargeLog
    },
    component: RechargeRecord
  }
];
