import lazyRoute from '@/router/lazyRoute';
import { customMenuMap } from '../../js/menu';
const OrderWithdraw = () => {
  lazyRoute.show();
  return import('@/vuePage/withdraw/OrderWithdraw.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const WithdrawLog = () => {
  lazyRoute.show();
  return import('@/vuePage/withdraw/WithdrawLog.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const OrderWithdraw2 = () => {
  lazyRoute.show();
  return import('@/vuePage/withdraw/OrderWithdraw2.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const WithdrawLog2 = () => {
  lazyRoute.show();
  return import('@/vuePage/withdraw/WithdrawLog2.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};
const WithdrawSetting = () => {
  lazyRoute.show();
  return import('@/vuePage/withdraw/WithdrawSetting.vue')
    .then((res) => {
      return lazyRoute.success(res);
    })
    .catch((err) => {
      return lazyRoute.error();
    });
};

export default [
  {
    path: 'withdrawSetting',
    name: 'withdrawSetting',
    meta: {
      menuId: customMenuMap.withdrawSetting
    },
    component: WithdrawSetting
  },
  {
    path: 'withdrawLog',
    name: 'withdrawLog',
    meta: {
      menuId: customMenuMap.withdrawLog
    },
    component: WithdrawLog
  },
  {
    path: 'orderWithdraw',
    name: 'orderWithdraw',
    meta: {
      menuId: customMenuMap.orderWithdraw
    },
    component: OrderWithdraw
  },
  {
    path: 'withdrawLog2',
    name: 'withdrawLog2',
    meta: {
      menuId: customMenuMap.withdrawLog2
    },
    component: WithdrawLog2
  },
  {
    path: 'orderWithdraw2',
    name: 'orderWithdraw2',
    meta: {
      menuId: customMenuMap.orderWithdraw2
    },
    component: OrderWithdraw2
  }
];
