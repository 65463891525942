/**
 * Created by Administrator on 2018/4/25.
 */
import Vue from 'vue';
import { stampFormat } from '../utils/timeFormat';
Vue.filter('hmMoney', (value) => {
  let money = Number.parseFloat(value);
  if (isNaN(money)) {
    return 'invalid ￥';
  } else {
    money = money.toFixed(2);
    return `￥${money}`;
  }
});
Vue.filter('size', (value) => {
  let size = '';
  let fileSize = 0;
  try {
    fileSize = Number(value);
  } catch (err) {}
  if (fileSize >= 1024 * 1024) {
    //    大约1M
    let sizeM = (fileSize / (1024 * 1024)).toFixed(2);
    size = `${sizeM}MB`;
  } else {
    let sizeK = (fileSize / 1024).toFixed(2);
    size = `${sizeK}KB`;
  }
  return size;
});

Vue.filter('encode', (value, first, second) => {
  return `${first}${encodeURIComponent(value)}${second ? second : ''}`;
});

Vue.filter('ArrayFormat', (value) => {
  return value.join('、');
});

Vue.filter('stampFormat', (val) => {
  return stampFormat(val);
});
